import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2"
import { ContextProvider } from "./context"
import Router from "./routes"

import "../src/i18n"

const App = () => {
  return (
    <ThemeProvider>
      <ContextProvider>
        <Router />
      </ContextProvider>
    </ThemeProvider>
  )
}

export default App
