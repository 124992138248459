import { trpc } from "src/api/trpc"
import _ from "lodash-es"

import {
  StyledDescription,
  StyledModal,
  StyledTitle,
  StyledButtonContainer,
  StyledIconContainer,
  StyledIcon,
  StyledSectionContainer,
} from "./styles"

import {
  Button,
  IconButton,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"

const RemoveDependentModal = ({
  isOpen,
  handleClose,
  candidateId,
  dependentId,
}: {
  isOpen: boolean
  handleClose: () => void
  candidateId: string
  dependentId: string
}) => {
  const utils = trpc.useContext()
  const { mutate: removeDependent, isLoading } =
    trpc.candidate.deleteDependent.useMutation({
      onSuccess: () => utils.wizard.getDependentDocuments.invalidate(),
    })

  return (
    <StyledModal
      open={isOpen}
      onClose={() => handleClose()}
      icon={
        <StyledIconContainer>
          <StyledIcon size={48} name="IconAlertCircle" fill="transparent" />
        </StyledIconContainer>
      }
    >
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "46px",
            justifyContent: "space-between",
          }}
        >
          <StyledSectionContainer>
            <StyledTitle
              variant={"headline7"}
              style={{ marginTop: "16px", maxWidth: "308px" }}
            >
              Tem certeza que deseja excluir este dependente?
            </StyledTitle>
            <StyledDescription
              variant={"body3"}
              style={{ marginTop: "16px", maxWidth: "270px" }}
            >
              Todos os dados ligados a este dependente serão perdidos.{" "}
            </StyledDescription>
          </StyledSectionContainer>
          <IconButton
            size="small"
            variant="line"
            icon="IconX"
            style={{ marginRight: "40px" }}
            onClick={() => handleClose()}
          />
        </div>

        <StyledButtonContainer>
          <Button
            size={"large"}
            variant="secondary"
            style={{ alignSelf: "center" }}
            onClick={() => handleClose()}
          >
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Cancelar
            </Typography>
          </Button>
          <Button
            size={"large"}
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={async () => {
              removeDependent({ dependentId, candidateId })
              handleClose()
            }}
          >
            {isLoading ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  Excluir dependente
                </Typography>
              </>
            )}
          </Button>
        </StyledButtonContainer>
      </>
    </StyledModal>
  )
}

export { RemoveDependentModal }
