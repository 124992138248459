import { ConfirmationDocumentsWithValue, FieldValue } from "server/src/types"
import { parseJWT } from "@flash-hros/utility"
import { MandatoryField } from "src/pages/MainPage/screens/DependentsStep/RenderDependents"

const convertDataURIToBinary = (dataURI: string) => {
  const BASE64_MARKER = ";base64,"
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
  const base64 = dataURI.substring(base64Index)
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i)
  }
  return array
}

const dataURLtoFile = (dataurl: any, filename: any) => {
  const arr = dataurl.split(",") || []
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

function isEmpty(obj: any) {
  const isEmptyString = obj === ""
  const isNull = obj === null
  const isUndefined = obj === undefined

  if (isEmptyString || isNull || isUndefined) {
    return true
  }

  return false
}

const hasRequiredFieldWithoutValue = (
  candidateDocuments: any[] | undefined
) => {
  if (candidateDocuments === undefined) return

  const res = candidateDocuments.map((cd) => {
    const formattedFields = cd.fields.filter(
      (field) =>
        field.enabled && field.fieldRule?.required && isEmpty(field.value)
    )

    return { _id: cd._id, title: cd.title, fields: formattedFields }
  })

  return res
}

const getHiringTypeFromToken = (encodedToken: any) => {
  const { setupId, hiringType } = parseJWT({ token: encodedToken })
  if (hiringType) return hiringType

  const setupList = [
    { setupId: "jxL-VEZtHAQhtO4HrECIz", hiringType: "clt" },
    { setupId: "3-C8awrulyz6PCzhtPRH3", hiringType: "pj" },
    { setupId: "hWxGc5x7F1qGvr3ztdMUz", hiringType: "internship" },
  ]

  return setupList.find((obj) => obj.setupId === setupId)?.hiringType || "clt"
}

const firstCapitalLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const shallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export const removeTokenFromURL = (urlParams: URLSearchParams) => {
  urlParams.delete("token")

  let url = window.location.pathname

  const newSearch = urlParams.toString()
  const hasQueryParams = newSearch.toString() !== ""
  if (!hasQueryParams) url += newSearch

  window.history.replaceState({}, "", `${url}${window.location.hash}`)
}

export const downloadArchive = async (value: any, nameOfArchive: string) => {
  const readFile = await fetch(value, {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
  })

  const fileToArrayBuffer = await readFile.arrayBuffer()
  const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
  const a = document.createElement("a")
  a.href = res
  a.download = nameOfArchive
  a.click()

  URL.revokeObjectURL(res)
}

export const convertMandatoryFieldToFieldValue = (
  field: MandatoryField,
  value: any,
  candidateId: string
): FieldValue => {
  const { fieldName, ...rest } = field

  return {
    candidateId,
    fieldId: fieldName,
    value,
    ...rest,
  } as FieldValue
}

const CpfMask = (cpfValue: string) => {
  return cpfValue
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
}

const validateCPF = (strCPF) => {
  if (!strCPF) return false
  strCPF = strCPF.replace(/[^\d]+/g, "")
  let sum
  let remainder
  sum = 0
  if (
    strCPF.length !== 11 ||
    strCPF === "00000000000" ||
    strCPF === "11111111111" ||
    strCPF === "22222222222" ||
    strCPF === "33333333333" ||
    strCPF === "44444444444" ||
    strCPF === "55555555555" ||
    strCPF === "66666666666" ||
    strCPF === "77777777777" ||
    strCPF === "88888888888" ||
    strCPF === "99999999999"
  ) {
    return false
  }

  for (let i = 1; i <= 9; i += 1) {
    sum += parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  }
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(strCPF.substring(9, 10))) return false
  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  }
  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(strCPF.substring(10, 11))) return false
  return true
}

export function getFileExtension(fileName: string) {
  const splitted = fileName.split(".")
  return splitted[splitted.length - 1]
}

export function removeDropzoneTimestamp(fileName: string) {
  const splitted = fileName.split("-")
  if (splitted.length === 1) return fileName

  const lengthMaxExtensionCanHave = 4
  const hasOnlyTimeStampOnString =
    splitted.length === 2 && splitted[1]?.length <= lengthMaxExtensionCanHave
  if (hasOnlyTimeStampOnString) return splitted.join("")

  return splitted.slice(1).join("-")
}

export {
  convertDataURIToBinary,
  dataURLtoFile,
  isEmpty,
  hasRequiredFieldWithoutValue,
  getHiringTypeFromToken,
  firstCapitalLetter,
  CpfMask,
  validateCPF,
}

export * from "./common-styled"
