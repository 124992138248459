import { useParams } from "react-router-dom"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  HeaderContent,
  CompanyName,
  LogoContainer,
  AvatarCircle,
  CenterContainer,
  RightContainer,
  ProgressBarContainer,
  ProgressBar,
} from "./styles"

import { BreadCrumbs } from "../../"

import Logo from "../../../../assets/logo_flash.svg"
import { useMemo } from "react"
import { Candidate, Card, Company, HiringFlowList } from "server/src/types"

const Desktop = ({
  candidate,
  company,
  card,
  flowList,
}: {
  candidate: Candidate
  company: Company
  card: Card
  flowList: HiringFlowList
}) => {
  const { route } = useParams()
  const isSmallScreen = useMediaQuery("(max-width: 1010px)")

  const progress = useMemo(() => {
    switch (route) {
      case "basic-data":
        return "25%"
      case "candidate":
        return "50%"
      case "dependents":
        return "75%"
      case "confirmation":
        return "100%"
      default:
        return "0%"
    }
  }, [route])

  return (
    <Container>
      <HeaderContent>
        <LogoContainer>
          <AvatarCircle
            hasLogo={!!company?.logo?.path}
            logoPath={company?.logo?.path || ""}
          />
          {!isSmallScreen && (
            <CompanyName>
              {company?.name || company?.legalName || ""}
            </CompanyName>
          )}
        </LogoContainer>
        <CenterContainer>
          <BreadCrumbs candidate={candidate} />
        </CenterContainer>
        <RightContainer>
          <Logo style={{ marginLeft: "8px" }} />
        </RightContainer>
        <ProgressBarContainer>
          <ProgressBar
            initial={{ opacity: 1, width: 0 }}
            animate={{ opacity: 1, width: progress }}
            transition={{ duration: 0.7 }}
          />
        </ProgressBarContainer>
      </HeaderContent>
    </Container>
  )
}

export default Desktop
