import { useMemo } from "react"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"

import Desktop from "./Desktop"
import Mobile from "./Mobile"
import { Company, HiringCard, Candidate } from "server/src/types"

const ConfirmProposal = ({
  company,
  card,
  candidate,
}: {
  company: Company
  card: HiringCard
  candidate: Candidate
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const ComponentRender = useMemo(
    () => (isMobile ? Mobile : Desktop),
    [isMobile]
  )

  return <ComponentRender candidate={candidate} card={card} company={company} />
}

export default ConfirmProposal
