import { useState, createRef, useEffect } from "react"
import {
  Button,
  IconButton,
  Icons,
  LinkButton,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CompanyTrack, EmployeeTrack } from "src/types"
import { createSegmentTrack } from "@Utils/segment"
import dispatchToast from "@Utils/dispatchToast"
import { StyledSignContainer } from "./styles"

interface Props {
  dismiss: () => void
  update?: any
  lastDrawing?: any
  confirm: any
  loading: boolean
  segmentTrack: CompanyTrack | EmployeeTrack
  modalInfo?: any
}

export const Drawing = ({
  dismiss,
  lastDrawing,
  update,
  confirm,
  loading,
  segmentTrack,
  modalInfo,
}: Props) => {
  const svgRef = createRef<SVGSVGElement>()
  const canvasRef = createRef<any>()

  const [paths, setPaths] = useState<Array<[string, number, number]>>([])
  const [pathsUntil, setPathsUntil] = useState<any>([])
  const [svgX, setSvgX] = useState(0)
  const [svgY, setSvgY] = useState(0)

  const [minX, setMinX] = useState(Infinity)
  const [minY, setMinY] = useState(Infinity)

  const [maxX, setMaxX] = useState(0)
  const [maxY, setMaxY] = useState(0)

  const [drawing, setDrawing] = useState(false)

  useEffect(() => {
    const svg = svgRef.current
    if (!svg) return
    const { x, y } = svg.getBoundingClientRect()
    setSvgX(x)
    setSvgY(y)
  }, [svgRef])

  useEffect(() => {
    if (lastDrawing && Object.keys(lastDrawing).length) {
      const { minX, minY, maxX, maxY, paths } = lastDrawing
      setPaths(paths)
      setMinX(minX)
      setMinY(minY)
      setMaxX(maxX)
      setMaxY(maxY)
    }
  }, [lastDrawing])

  useEffect(() => {
    if (modalInfo && Object.keys(modalInfo).length) {
      const { minX, minY, maxX, maxY, paths } = modalInfo
      setPaths(paths)
      setMinX(minX)
      setMinY(minY)
      setMaxX(maxX)
      setMaxY(maxY)
    }
  }, [modalInfo])

  const resetDrawingBoard = () => {
    if (segmentTrack)
      createSegmentTrack({ track: segmentTrack.signContractClean })

    setPaths([])
    setMinX(Infinity)
    setMaxX(0)
    setMinY(Infinity)
    setMaxY(0)
  }

  const handleFinish = () => {
    if (segmentTrack)
      createSegmentTrack({ track: segmentTrack.signContractSign })

    if (!paths.length) {
      dispatchToast({
        variant: "toast",
        type: "error",
        title: "Por favor, desenhe sua assinatura",
      })
      return
    }
    const dx = -(minX - 10)
    const dy = -(minY - 10)
    const width = maxX - minX + 20
    const height = maxY - minY + 20

    if (modalInfo?.id) {
      update({
        id: modalInfo?.id,
        originWidth: width,
        originHeight: height,
        paths: paths,
        path: paths.reduce(
          (fullPath, lineItem) =>
            `${fullPath}${lineItem[0]}${lineItem[1] + dx}, ${lineItem[2] + dy}`,
          ""
        ),
      })
    } else {
      confirm({
        minX,
        minY,
        maxX,
        maxY,
        originWidth: width,
        originHeight: height,
        paths: paths,
        path: paths.reduce(
          (fullPath, lineItem) =>
            `${fullPath}${lineItem[0]}${lineItem[1] + dx}, ${lineItem[2] + dy}`,
          ""
        ),
      })
    }
  }

  const closeModal = () => {
    if (segmentTrack)
      createSegmentTrack({ track: segmentTrack.signContractCancel })
    resetDrawingBoard()
    dismiss()
  }

  return (
    <>
      <StyledSignContainer>
        <div
          style={{
            position: "absolute",
            top: 16,
            left: 16,
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconArrowBackUp"
            onClick={() => {
              if (!pathsUntil.length) return

              const array = pathsUntil
              const arrayToRemove = array.splice(-1)?.[0]

              const filterArray = arrayToRemove.length
                ? paths.filter(
                    (a: any) => !!!arrayToRemove.some((p: any) => p === a)
                  )
                : []

              setPathsUntil(array)
              setPaths(filterArray)
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: 16,
            right: 16,
          }}
        >
          <LinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={resetDrawingBoard}
          >
            Limpar
          </LinkButton>
        </div>

        <div
          role={"presentation"}
          ref={canvasRef}
          onMouseDown={(event: any) => {
            setDrawing(true)
            const x = event.clientX - svgX
            const y = event.clientY - svgY

            setMinX(Math.min(minX, x))
            setMaxX(Math.max(maxX, x))
            setMinY(Math.min(minY, y))
            setMaxY(Math.max(maxY, y))
            setPaths([...paths, ["M", x, y]])
          }}
          onMouseMove={(event: any) => {
            if (!drawing) return

            const x = event.clientX - svgX
            const y = event.clientY - svgY

            setMinX(Math.min(minX, x))
            setMaxX(Math.max(maxX, x))
            setMinY(Math.min(minY, y))
            setMaxY(Math.max(maxY, y))
            setPaths([...paths, ["L", x, y]])
          }}
          onMouseUp={() => {
            setDrawing(false)
            const array = pathsUntil
            const merge = array.flat(1)

            const filterArray = pathsUntil.length
              ? paths.filter((a: any) => !!!merge.some((p: any) => p === a))
              : paths

            if (!filterArray.length) {
              setPathsUntil([])
              return
            }

            array.push(filterArray)
            setPathsUntil(array)
          }}
        >
          <svg
            id="signSvg"
            ref={svgRef}
            style={{
              width: "100%",
              height: "250px",
              zIndex: 99,
            }}
          >
            <path
              strokeWidth={5}
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={"black"}
              fill="none"
              d={paths.reduce(
                (fullPath, lineItem) =>
                  `${fullPath}${lineItem[0]}${lineItem[1]}, ${lineItem[2]}`,
                ""
              )}
            />
          </svg>
          <div
            style={{
              position: "relative",
              zIndex: 1,
            }}
          >
            <div
              style={{
                position: "absolute",
                background: "#D1C7CE",
                height: "1px",
                left: "16px",
                right: "16px",
                bottom: "40px",
              }}
            />
          </div>
        </div>
      </StyledSignContainer>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
      >
        <LinkButton
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={closeModal}
        >
          Voltar
        </LinkButton>
        <Button
          size="large"
          variant="primary"
          style={{ alignSelf: "center" }}
          disabled={!!loading}
          onClick={handleFinish}
        >
          {loading ? (
            <Loader size="small" variant="secondary" />
          ) : (
            <>
              Assinar
              <Icons
                name="IconArrowRight"
                style={{
                  fill: "transparent",
                  color: "#fff",
                  marginLeft: "8px",
                }}
              />
            </>
          )}
        </Button>
      </div>
    </>
  )
}
