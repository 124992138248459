import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 647px;

  margin: 48px 40px;
`

export const StepTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 22px;
`

export const ContentArea = styled.div`
  border: 1px solid #ebe5e9;
  border-radius: 8px;
  padding: 40px 44px;
  margin-bottom: 40px;
  div:last-child {
    margin-bottom: 0px;
  }
`

export const ItemArea = styled.div`
  display: flex;
  justify-content: left;
  margin-bottom: 24px;
`

export const ItemIcon = styled.div`
  margin-right: 25px;
  fill: #55444f;
`

export const ItemTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #856f7e;
`

export const HtmlItemSimpleText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6d5a66;
`

export const ItemSimpleText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #6d5a66;
`

export const Img = styled.img`
  width: 100%;
`

export const List = styled.ul`
  margin-left: 65px;
  margin-top: -25px;
  li {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #6d5a66;
  }
`

export const ImgArea = styled.div`
  padding-right: 100px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: end;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    padding-right: 30px;
  }
`

export const IconButton = styled.div`
  div {
    background: white;
    top: -63px;
    position: relative;
    right: 16px;
    padding: 12px;
    border-radius: 30px;
    svg {
      fill: #55444f;
    }
  }
`

export const ButtonArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    button:last-child {
      margin-top: 20px;
    }
  }
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 120px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 32px 16px 16px;
  border: 1px solid #b9acb4;
  border-radius: 12px;
  margin-top: 24px;
`

export const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: Cabinet Grotesk;
`

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: #83727d;
  font-weigth: 600;
  margin-bottom: 40px;
`

export const StyledSubtitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #83727d;
`

export const UploadPreview = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
  padding-top: 32px;

  border: 1px solid #ebe6e9;
  border-radius: 10px;
  margin-bottom: 20px;
`

export const UploadPreviewArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const UploadIconContainer = styled.div`
  margin-right: 7px;
  border-radius: 4px;
  display: flex;
`

export const UploadFileName = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #3b2e37;
`

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom-color: ${({ theme }) => theme.colors.primary};
      align-self: center;

      &:before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
      }

      &:active {
        color: ${({ theme }) => theme.colors.primary};

        &:before {
          border-bottom-color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`

export const LeftContainer = styled.div`
  max-width: 324px;
  margin-right: 92px;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-right: 40px;
  }
`

export const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  max-width: 348px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

export const TextDeny = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.primary};
`

export const TextConfirm = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.neutral[100]};
`
