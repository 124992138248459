interface LogoProps {
  width?: string
  height?: string
}

export const Logo = ({ width = "40", height = "40" }: LogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
    >
      <rect width={width} height={height} rx="20" fill="#FE2B8F" />
      <path
        d="M19.1476 31.7601C19.383 31.764 19.6168 31.7201 19.8346 31.6311C20.0523 31.5421 20.2495 31.4098 20.414 31.2424C20.5785 31.075 20.7069 30.876 20.7912 30.6576C20.8756 30.4392 20.9141 30.2059 20.9045 29.9722V18.948C20.9019 18.9045 20.9087 18.861 20.9243 18.8204C20.94 18.7797 20.9641 18.7428 20.9952 18.7122C21.0263 18.6815 21.0637 18.6578 21.1047 18.6425C21.1458 18.6273 21.1896 18.6209 21.2333 18.6238H23.1961C23.6388 18.6238 24.0633 18.4491 24.3764 18.1381C24.6894 17.8272 24.8652 17.4054 24.8652 16.9656C24.8652 16.5258 24.6894 16.104 24.3764 15.793C24.0633 15.4821 23.6388 15.3074 23.1961 15.3074H21.2434C21.2001 15.3095 21.1568 15.3026 21.1163 15.2871C21.0759 15.2715 21.0392 15.2477 21.0086 15.2171C20.9781 15.1865 20.9544 15.1499 20.939 15.1096C20.9237 15.0693 20.9171 15.0262 20.9196 14.9832V14.1703C20.9196 12.6741 21.7378 11.7989 23.2112 11.6767C24.3884 11.5794 25.0409 10.9635 25.0409 9.95611C25.0359 9.03597 24.4486 8.32031 23.1384 8.32031C19.8027 8.32031 17.383 10.8563 17.383 14.2675V14.9832C17.3845 15.0261 17.377 15.0689 17.3608 15.1088C17.3447 15.1487 17.3204 15.1848 17.2895 15.2148C17.2586 15.2449 17.2217 15.2682 17.1812 15.2832C17.1407 15.2983 17.0974 15.3048 17.0542 15.3024H16.6301C16.4109 15.3024 16.1938 15.3453 15.9913 15.4286C15.7888 15.5119 15.6048 15.6341 15.4498 15.7881C15.2948 15.942 15.1719 16.1248 15.088 16.326C15.0041 16.5272 14.9609 16.7428 14.9609 16.9606C14.9609 17.1784 15.0041 17.394 15.088 17.5952C15.1719 17.7964 15.2948 17.9792 15.4498 18.1332C15.6048 18.2871 15.7888 18.4093 15.9913 18.4926C16.1938 18.576 16.4109 18.6188 16.6301 18.6188H17.0542C17.0979 18.6163 17.1415 18.623 17.1824 18.6384C17.2233 18.6537 17.2604 18.6775 17.2914 18.7081C17.3225 18.7386 17.3466 18.7754 17.3624 18.8158C17.3782 18.8563 17.3852 18.8997 17.383 18.943V29.9647C17.3731 30.1985 17.4114 30.4319 17.4956 30.6505C17.5798 30.8691 17.7081 31.0683 17.8727 31.2358C18.0373 31.4032 18.2346 31.5355 18.4526 31.6244C18.6705 31.7132 18.9045 31.7569 19.14 31.7526"
        fill="white"
      />
    </svg>
  )
}
