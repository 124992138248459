import { DeprecatedModal } from "@Components"
import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

const PreviewDocContainer = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  cursor: pointer;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    &.Mui-checked {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
`

const AccordionChildren = styled.div`
  border: 1px solid #e8e3e6;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 44px 40px 44px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const AccordionChildrenItems = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
  }
`

const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background-color: ${({ theme }) => theme?.colors?.primary};

  border-radius: 4px 16px 4px 4px;

  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  p {
    color: #fff;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin-top: 10px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  padding: 40px 100px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 40px;
  }
`

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Title = styled.span`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  font-family: Cabinet Grotesk;
`

const SubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #856f7e;
  margin-bottom: 64px;
`

const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #55444f;
  margin-bottom: 24px;
`

const SectionSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #856f7e;
  margin-bottom: 32px;
`

const FlexColumn = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;

  width: ${({ width }) => width || "100%"};

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    width: 100%;
  }
`

const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

const IconContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #f6f3f6;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
  }
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-container {
      border-radius: 16px;
      width: 870px;
      overflow: hidden;
    }
    .modal-content-area {
      padding: 0px;
      overflow: auto;
    }
  }
`

const ChildrenContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`
export {
  PreviewImageContainer,
  PreviewDocContainer,
  PreviewTextContainer,
  AccordionChildren,
  AccordionChildrenItems,
  ButtonContainer,
  ConfirmButton,
  Container,
  CloseContainer,
  Title,
  SubTitle,
  SectionTitle,
  SectionSubTitle,
  FlexColumn,
  IconContainer,
  StyledModal,
  ChildrenContainer,
}
