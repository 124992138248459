import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { uploadHelper } from "@flash-hros/utility"
import { toPng } from "html-to-image"
import html2canvas from "html2canvas"
import { ConfirmationDataType } from "../.."
import {
  HiringCard,
  ResignationCard,
  SignType,
  SignatureFile,
} from "server/src/types"
import { Context } from "src/context"
import { dataURLtoFile } from "@Utils/index"
import { trackList } from "@Utils/segment"
import dispatchToast from "@Utils/dispatchToast"
import { trpc } from "src/api/trpc"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Mobile } from "./Mobile"
import { Desktop } from "./Desktop"

const SignData = ({
  card,
  confirmationData,
}: {
  card: HiringCard | ResignationCard
  confirmationData?: ConfirmationDataType
}) => {
  const [attachments, setAttachments] = useState<any>([])

  const navigate = useNavigate()
  const utils = trpc.useContext()

  const {
    token,
    category,
    subcategory,
    type: userType,
    name,
  } = useContext(Context)

  const isMobile = useMediaQuery("(max-width: 768px)")

  const { mutateAsync: submitSignContract, isLoading: isSignLoading } =
    trpc.wizard.submitSignContractV2.useMutation()
  const { mutateAsync: removeLimitDate } =
    trpc.hiring.removeLimitDate.useMutation()

  useEffect(() => {
    if (
      !confirmationData?.documentNumber ||
      !confirmationData?.email ||
      !confirmationData?.name
    ) {
      dispatchToast({
        type: "error",
        content: "Por favor preencha os dados",
      })
      navigate(`../wizard/signing/confirmData`, {
        replace: true,
      })
    }
  }, [confirmationData])

  async function handleSigned(uploadedFile: SignatureFile) {
    if (
      !confirmationData?.name ||
      !confirmationData.email ||
      !confirmationData.documentNumber
    ) {
      dispatchToast({
        variant: "toast",
        type: "error",
        title: "Algumas informações não foram encontradas.",
        description: "Por favor, volte para a etapa de Confirmar dados.",
      })
      return
    }

    submitSignContract({
      flowCardId: card._id,
      name: name,
      userType: userType as SignType,
      signatureFile: uploadedFile,
      confirmationData: confirmationData,
      flowType: category,
      cardVersion: card.version,
      columnId: card.columnId,
    })
      .then(async ({ routeRedirect }) => {
        utils.wizard.getSigningData.invalidate()
        if (category === "hiring") {
          const hiringCard = card as HiringCard
          const limitDate = hiringCard.limitDates.find((limitDate) =>
            limitDate.step.includes("signContract")
          )
          if (limitDate) {
            await removeLimitDate({
              cardId: card._id,
              step: limitDate.step,
            })
          }
        }

        navigate(`../wizard/signing/${routeRedirect}`, {
          replace: true,
        })
      })
      .catch(() => {
        dispatchToast({
          type: "error",
          content: "Houve um problema ao assinar o contrato",
        })
      })
  }

  const uploadSignatureFile = async ({
    name,
    file,
  }: {
    name: any
    file: any
  }): Promise<SignatureFile> => {
    const { companyId, candidateId, employeeId, _id } = card as any
    const userId = category === "hiring" ? candidateId : employeeId

    const url = `${companyId + "/" + userId + "/" + _id + "/signature"}`

    const { key, path } = await uploadHelper({
      key: url,
      file,
      fileName: name,
      module: "employee-pii",
      externalToken: token,
    })

    return { key, path, origin: "internal" }
  }

  const addTextField = async () => {
    const response = await html2canvas(
      document.getElementById("textToUpload") as HTMLElement,
      {
        onclone: function (doc: any) {
          const spanToUnhidden = doc.getElementById("textToUpload")
          spanToUnhidden.style.display = "block"
        },
      }
    )
    if (!response) return

    const dataURL = response.toDataURL()
    const file = dataURLtoFile(dataURL, "signature.png")
    const uploadedFile = await uploadSignatureFile({
      name: `signature_${userType}_${name}.png`,
      file,
    })

    await handleSigned(uploadedFile)
  }

  const updateText = ({ id, text }: any) => {
    const allObjects = attachments.map((attachment) =>
      attachment.id === id ? { ...attachment, text } : attachment
    )

    setAttachments(allObjects)
  }

  const addDrawing = async () => {
    const htmlText = document.getElementById("signSvg") as HTMLElement
    const base64 = await toPng(htmlText)
    if (!base64) return

    const res = await fetch(base64)
    const blob = await res.blob()
    const file = new File([blob], "signature", { type: "image/png" })

    const uploadedFile = await uploadSignatureFile({
      name: `signature_${userType}_${name}.png`,
      file,
    })

    await handleSigned(uploadedFile)
  }

  const updateDrawing = ({
    id,
    originWidth,
    originHeight,
    path,
    paths,
  }: any) => {
    const object = {
      id,
      path,
      paths,
      originWidth,
      originHeight,
      width: originWidth,
      height: originHeight,
      scale: 1,
    }

    const allObjects = attachments.map((attachment) =>
      attachment.id === id ? { ...attachment, ...object } : attachment
    )

    setAttachments(allObjects)
  }

  const trackUserList = {
    ["candidate"]: trackList?.[subcategory]?.employee,
    ["company"]: trackList?.[subcategory]?.company,
    ["institution"]: trackList?.[subcategory]?.institution,
  }

  const track = trackUserList?.[userType]

  return isMobile ? (
    <Mobile
      attachments={attachments}
      addDrawing={addDrawing}
      addTextField={addTextField}
      isLoading={isSignLoading}
      updateDrawing={updateDrawing}
      updateText={updateText}
      track={track}
    />
  ) : (
    <Desktop
      attachments={attachments}
      addDrawing={addDrawing}
      addTextField={addTextField}
      isLoading={isSignLoading}
      updateDrawing={updateDrawing}
      updateText={updateText}
      track={track}
    />
  )
}

export default SignData
