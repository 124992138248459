import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 647px;
  max-height: 500px;
  border-radius: 8px;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: 440px;
    padding: 0px 20px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 360px;
    padding: 0px 20px;
  }
`

export const ProposalTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;
  span {
    text-transform: capitalize;
  }
`

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: -37px 0px 37px;
  display: flex;
  font-size: 80px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
`

export const ProposalSubtitle = styled(Typography)`
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 25px;
`

export const ExpireDate = styled(Typography)`
  font-weight: 600;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`

export const Description = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 47px;
`

export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a2909c;
  margin-top: 35px;
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const TextArea = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[60]};
`
