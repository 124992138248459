import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 647px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 0px 36px 56px 36px;
`

export const ProposalTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;
  span {
    text-transform: capitalize;
  }
`

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: -37px 0px 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 80px;
  font-weight: bold;
  color: white;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const ProposalSubtitle = styled(Typography)`
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 25px;
`

export const ExpireDate = styled(Typography)`
  font-weight: 600;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`

export const Description = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 47px;
`

export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 35px;
  padding-bottom: 35px;
`

export const TextArea = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[60]};
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
