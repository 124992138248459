import styled from "styled-components"

const StyledText = styled.span`
  font-family: LiuJianMaoCao;
`

const StyledSignContainer = styled.div`
  background: #f8f6f8;
  border: 1px solid #ebe6e9;
  border-radius: 10px;
  margin-top: 8px;
  width: 100%;
  height: 270px;
  position: relative;
`

export { StyledSignContainer, StyledText }
