import styled from "styled-components"

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f8f9;
  overflow: hidden;
`

const PageContents = styled.div<{ hasHeader?: boolean }>`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 37px 24px;

  ${({ hasHeader }) => !hasHeader && "justify-content: center"};

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
`

const StepContainer = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 1440px;
  flex: 1;
`

const LoadingArea = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export { MainContainer, PageContents, StepContainer, LoadingArea }
