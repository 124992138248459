import { Outlet, Navigate } from "react-router-dom"
import { getFromLS, setInLS } from "@flash-hros/utility"
import { useAuthGuard } from "@Hooks"
import { removeTokenFromURL } from "@Utils/index"

const AuthGuard = ({ children }: { children?: JSX.Element }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const tokenFromURL = urlParams.get("token")

  const token = tokenFromURL || getFromLS("wizard-token")

  if (tokenFromURL) {
    setInLS({ key: "wizard-token", value: token })
    removeTokenFromURL(urlParams)
  }

  const { isValidToken, setToken } = useAuthGuard(token)
  if (isValidToken()) {
    setToken()
    return children ? children : <Outlet />
  }

  return <Navigate to={"/wizard/invalid?type=expired"} />
}

export default AuthGuard
