import styled from "styled-components"

export const ConfirmButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border-radius: 12px;
  width: 290px;
  cursor: pointer;
  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }
  @media screen and (max-width: 1336px) {
    width: 200px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const DenyButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: #ffffff;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  width: 290px;
  cursor: pointer;
  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }
  @media screen and (max-width: 1336px) {
    width: 200px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
