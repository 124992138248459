import { useState, useContext, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { Container, StyledPdfHeaderContainer, StyledButton } from "./styles"
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2"
import { Contract, SignType } from "server/src/types"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { removeDropzoneTimestamp } from "@Utils/index"

const pdfjsLib = require("pdfjs-dist")
const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry")

const pdfLib = require("pdf-lib")
const download = require("downloadjs")

const VisualizeContract = ({
  contract,
  setCurrentPage: setActualPage,
  setTotalPages,
  setNextHandleClick,
}: {
  contract: Contract
  setCurrentPage: (page: number) => void
  setTotalPages: (pages: number) => void
  setNextHandleClick: any
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const navigate = useNavigate()
  const { type: userType, subcategory } = useContext(Context)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const [name, setName] = useState("")
  const [pageIndex, setPageIndex] = useState(-1)
  const [pages, setPages] = useState<any>([])

  const [scale, setScale] = useState(1)
  const [currentPage, setCurrentPage] = useState(null)

  useEffect(() => {
    if (pageIndex + 1 == pages.length) {
      setCurrentPage(pages[pageIndex])
      setNextHandleClick(() => () => {
        if (track) createSegmentTrack({ track: track.pdfViewContinue })
        navigate(`../wizard/signing/confirmData`, {
          replace: true,
        })
      })
      return
    }

    if (pages.length && pageIndex >= 0) {
      setCurrentPage(pages[pageIndex])
      setNextHandleClick(() => () => {
        if (track) createSegmentTrack({ track: track.pdfViewContinue })
        setActualPage(pageIndex + 1)
        setPageIndex(pageIndex + 1)
      })
      return
    }
  }, [pages, pageIndex])

  useEffect(() => {
    const lastContractOutput =
      contract?.contractOutput?.[contract?.contractOutput?.length - 1]
    if (!lastContractOutput) return

    const file = lastContractOutput.value
    const key = lastContractOutput.key

    if (file) {
      ;(async () => {
        try {
          pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker
          const pdf = await pdfjsLib.getDocument(file).promise

          const promises = Array(pdf.numPages)
            .fill(0)
            .map(async (_, index) => {
              const page = await pdf.getPage(index + 1)
              return page
            })

          const pages = await Promise.all(promises)
          const lastIndexName = key.lastIndexOf("/")
          const nameOfArchive =
            removeDropzoneTimestamp(key?.substring(lastIndexName + 1)) ||
            "contrato.pdf"

          setName(nameOfArchive)
          setPages(pages)
          setPageIndex(0)
          setActualPage(0)
          setTotalPages(pages.length)
        } catch (e) {
          console.log(e)
          navigate(`../wizard/invalid?type=corrupted`)
        }
      })()
    }
  }, [contract])

  useEffect(() => {
    const renderPage = (p: any) => {
      const _page = p

      if (_page) {
        const context = canvasRef.current?.getContext("2d")
        const viewport = _page.getViewport({ scale: scale })
        setWidth(viewport.width)
        setHeight(viewport.height)

        if (context) {
          _page.render({
            canvasContext: canvasRef.current?.getContext("2d"),
            viewport,
          })
        }
      }
    }

    if (currentPage !== null) {
      renderPage(currentPage)
    }
  }, [currentPage, scale])

  const save = async () => {
    const lastContractOutput =
      contract?.contractOutput?.[contract?.contractOutput?.length - 1]
    if (!lastContractOutput) return

    const file = lastContractOutput.value
    if (!file) return

    let pdfDoc = {} as any

    const existingPdfBytes = await fetch(file).then((res) => res.arrayBuffer())
    const bytes = new Uint8Array(existingPdfBytes)

    pdfDoc = await pdfLib.PDFDocument.load(bytes)

    const pdfBytes = await pdfDoc.save()
    download(pdfBytes, name, "application/pdf")
  }

  const savePDF = async () => {
    if (!pages.length) return
    await save()
  }

  const trackUserList = {
    [SignType.candidate]: trackList?.[subcategory]?.employee,
    [SignType.company]: trackList?.[subcategory]?.company,
    [SignType.institution]: trackList?.[subcategory]?.institution,
  }

  const track = trackUserList?.[userType]

  return (
    <Container
      style={{
        overflow: "hidden",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#EBE6E9",
      }}
    >
      <StyledPdfHeaderContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconZoomOut"
            onClick={() => setScale(scale - 0.25)}
            style={{ marginRight: "16px" }}
          />

          <IconButton
            size="small"
            variant="line"
            icon="IconZoomIn"
            style={{ marginRight: "16px" }}
            onClick={() => setScale(scale + 0.25)}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronLeft"
            disabled={pageIndex - 1 < 0}
            onClick={() => {
              setCurrentPage(pages[pageIndex - 1])
              setActualPage(pageIndex - 1)
              setPageIndex(pageIndex - 1)
            }}
          />
          <span style={{ marginRight: "16px", marginLeft: "16px" }}>
            {pageIndex + 1}/{pages.length}
          </span>
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronRight"
            disabled={pageIndex + 2 > pages.length}
            onClick={() => {
              if (track) createSegmentTrack({ track: track.pdfViewNextPage })
              setCurrentPage(pages[pageIndex + 1])
              setActualPage(pageIndex + 1)
              setPageIndex(pageIndex + 1)
            }}
          />
        </div>
        <div>
          <StyledButton
            size="small"
            variant="primary"
            onClick={() => {
              if (track) createSegmentTrack({ track: track.pdfViewDownload })
              savePDF()
            }}
          >
            Baixar documento
          </StyledButton>
        </div>
      </StyledPdfHeaderContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
          margin: "12px",
        }}
      >
        <div style={{ position: "relative" }}>
          <canvas ref={canvasRef} width={width} height={height} />
        </div>
      </div>
    </Container>
  )
}

export default VisualizeContract
