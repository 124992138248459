import { StyleDate } from "./styles"

const InputDate = ({
  id,
  name,
  onChange,
  label,
  disabled,
  value,
  error,
  fullWidth,
  helperText,
  style,
  ...props
}: any) => {
  return (
    <StyleDate
      id={id}
      name={name}
      label={label}
      value={value}
      disabled={disabled}
      onDateChange={onChange}
      fullWidth={fullWidth}
      error={error}
      helperText={helperText}
      style={style}
      {...props}
    />
  )
}

export { InputDate }
