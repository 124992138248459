import styled from "styled-components"

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px 72px;
  border-top: 1px solid #ebe6e9;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export { Footer }
