import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  ContentWrapper,
  IconContainer,
  MainContainer,
  ModalContainer,
} from "./styles"
import { Navbar } from "@Components"
import { useTranslation } from "react-i18next"

interface StartProps {
  status: string
  handleNextStep: () => void
}

export const Start = ({ status, handleNextStep }: StartProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationAso.startStep",
  })

  return (
    <MainContainer>
      <Navbar />

      <Container>
        <ModalContainer>
          <IconContainer>
            <Icons name="IconReportMedical" fill="transparent" />
          </IconContainer>

          <ContentWrapper>
            <Typography variant="headline7" variantColor="#F20D7A">
              {t("title", {
                replace: {
                  type_of_send:
                    status === "resent" ? t("typeResent") : t("typeSend"),
                },
                interpolation: {
                  escapeValue: false,
                },
              })}
            </Typography>
            <Typography variant="body3" variantColor="#83727D">
              {t("description")}
            </Typography>
            <div>
              <Button variant="primary" size="large" onClick={handleNextStep}>
                {status === "resent"
                  ? t("buttonNextResent")
                  : t("buttonNextSend")}
              </Button>
            </div>
          </ContentWrapper>
        </ModalContainer>
      </Container>
    </MainContainer>
  )
}
