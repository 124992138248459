import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const StyledPre = styled.pre`
  font-family: "Nunito", sans-serif;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`

export const PreviewDocWithoutContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 24px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  pointer-events: none;
  width: 100%;

  max-width: 300px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 100%;
  }
`

export const PreviewDocContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 12px 24px 12px 12px;
  cursor: pointer;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  width: 100%;

  max-width: 300px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 100%;
  }
`

export const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

export const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
`

export const StyledPOneLine = styled.p`
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
