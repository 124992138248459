import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Drawer } from "@mui/material"
import styled from "styled-components"

export const StyledDrawer = styled(Drawer)<{ zIndex: number }>`
  &.MuiModal-root {
    z-index: ${({ zIndex }) => zIndex};

    div {
      &.MuiPaper-root {
        width: 50%;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
`

export const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0px 40px 40px 40px;
  display: flex;
  flex-direction: column;
`
