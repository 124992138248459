import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const HeaderContent = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 20px 43px;
`

export const StepContainer = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 1440px;
  flex: 1;
  margin-top: 40px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-top: 0px;
  }
`

export const LoadingArea = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const PageContents = styled.div<{ hasHeader?: boolean }>`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 37px 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
`

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f8f9;
  overflow: hidden;
`

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AvatarCircle = styled.div<{
  logoPath?: string
  hasLogo?: boolean
}>`
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme, hasLogo }) =>
    !!!hasLogo ? theme.colors.primary : "transparent"};
  background-image: url(${({ logoPath }) => logoPath});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

export const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a4a6ab;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

export const CompanyName = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #3c2d37;
  margin: 0 12px;
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a2909c;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #f8f6f8;
`

export const TextArea = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[60]};
`
