import { Container, Title, Text } from "./styles"
import { EmptyMessageProps } from "./types"

const EmptyMessage = ({ title, message, headerElement }: EmptyMessageProps) => (
  <Container>
    {headerElement ? headerElement : null}
    <Title variant="headline7">{title}</Title>
    <Text variant="body3">{message}</Text>
  </Container>
)

export { EmptyMessage }
