import styled from "styled-components"
import { Icons, LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { DeprecatedModal } from "@Components"

const StyledSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding: 0px 32px 0px 112px;
  text-align: center;
`

const StyledModal = styled(DeprecatedModal)`
  && {
    .modal-icon-container {
      top: -40px;
    }
    .modal-container {
      border-radius: 8px;
      width: 647px;
    }
    .modal-content-area {
      padding: 0px;
      overflow: auto;
    }
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px 112px;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`

const CloseContainer = styled.div``

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`

const StyledIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const StyledLinkButton = styled(LinkButton)``

export {
  StyledIcon,
  StyledIconContainer,
  StyledSectionContainer,
  CloseContainer,
  StyledModal,
  StyledButtonContainer,
  StyledTitle,
  StyledDescription,
  StyledLinkButton,
}
