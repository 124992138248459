import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import styled, { css } from "styled-components"

export const AccordionContainer = styled(Accordion)<{ isRefused: boolean }>`
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ isRefused, theme }) =>
      isRefused ? theme.colors.feedback.error[50] : theme.colors.neutral[90]};
  border-radius: ${(props) => props.theme.borders.radius.s};
  box-shadow: unset;
  margin: unset;
  width: 100%;

  &.Mui-disabled {
    background-color: unset;
  }
`

export const AccordionHeader = styled(AccordionSummary)`
  padding: ${(props) => props.theme.spacings.s};

  &.Mui-disabled {
    opacity: unset;
  }

  .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;

    .iconButton-custom-theme-filled {
      align-self: unset;
    }
  }

  .Mui-expanded {
    margin: 0;
  }
`

export const AccordionBody = styled(AccordionDetails)`
  border-top: 1px solid ${(props) => props.theme.colors.neutral[90]};
  padding: ${(props) => props.theme.spacings.s};
`

export const RefusedContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex: 1;
  margin-bottom: 24px;

  .icon-container {
    display: flex;
    align-items: flex-start;
    color: ${({ theme }) => theme.colors.feedback.error[40]};
    width: 16px;
    height: 16px;
  }
`

export const StyledPre = styled.pre`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`
