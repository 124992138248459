import { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  ExpireDate,
  Description,
  Avatar,
  LogoArea,
} from "./styles"

import { Button } from "@flash-tecnologia/hros-web-ui-v2"

import Logo from "../../../../assets/logo_flash.svg"
import {
  Contract,
  Candidate,
  HiringCard,
  ResignationCard,
  SignType,
} from "server/src/types"
import { Context } from "src/context"
import { createSegmentTrack, trackList } from "@Utils/segment"

export const Content = ({
  card,
  contract,
}: {
  card: HiringCard | ResignationCard
  contract: Contract
}) => {
  const navigate = useNavigate()
  const { expireDate } = useParams()
  const { subcategory, category, type } = useContext(Context)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const signature = contract?.signature || []

  const isCompany = type === SignType.company

  const isResignation = category === "resignation"

  const foundCandidate = signature.find(
    (s) => s.type === SignType.candidate || s.type === SignType.employee
  )

  const validationTypeOthers =
    subcategory === "pj" || card.setupId === "3-C8awrulyz6PCzhtPRH3"
      ? "o contrato do(a) prestador(a) de serviço"
      : subcategory === "internship" || card.setupId === "hWxGc5x7F1qGvr3ztdMUz"
      ? "o termo de compromisso de estágio do candidato(a)"
      : isResignation
      ? "os documentos rescisórios do(a)"
      : "o contrato do(a) candidato(a)"

  const validationTypeCandidate =
    subcategory === "pj" || card.setupId === "3-C8awrulyz6PCzhtPRH3"
      ? "hora de assinar seu contrato de prestação de serviço"
      : subcategory === "internship" || card.setupId === "hWxGc5x7F1qGvr3ztdMUz"
      ? "hora de assinar seu termo de compromisso de estágio"
      : isResignation
      ? "confira e assine seus documentos rescisórios"
      : t("signing.start.title")

  return (
    <>
      {isCompany ? (
        <Container>
          <Avatar>{foundCandidate?.name?.substring(0, 1)}</Avatar>
          <ProposalTitle variant={"headline6"}>
            <span>
              Hora de assinar {validationTypeOthers}{" "}
              <span style={{ textTransform: "capitalize" }}>
                {foundCandidate?.name?.split(" ")[0]}
              </span>
            </span>
          </ProposalTitle>
          {expireDate ? (
            <ExpireDate>Responda até 25/08/2022 às 22h</ExpireDate>
          ) : null}
          <Description variant={"body3"}>
            {category === "resignation"
              ? "Você recebeu documentos para análise e assinatura"
              : "Por favor assine o documento o mais rápido possível."}
          </Description>
          <Button
            onClick={() => {
              const track = trackList?.[subcategory]?.company?.analyzeDocument
              if (track)
                createSegmentTrack({
                  track: track,
                })

              navigate(`../wizard/signing/visualizeContract`, {
                replace: true,
              })
            }}
            variant="primary"
            size="large"
            style={{ alignSelf: "center" }}
          >
            {t("signing.start.buttonLabel")}
          </Button>
        </Container>
      ) : (
        <Container>
          <Avatar>{foundCandidate?.name?.substring(0, 1).toUpperCase()}</Avatar>
          <ProposalTitle variant={"headline6"}>
            <span>{foundCandidate?.name?.split(" ")[0]}</span>,{" "}
            {validationTypeCandidate}!
          </ProposalTitle>
          <ProposalSubtitle>
            {category === "resignation"
              ? "Você recebeu documentos para análise e assinatura"
              : t("signing.start.subtitle")}
          </ProposalSubtitle>
          {expireDate ? (
            <ExpireDate>Responda até 25/08/2022 às 22h</ExpireDate>
          ) : null}
          {category === "resignation" ? (
            <></>
          ) : (
            <Description variant={"body3"}>
              {t("signing.start.description")}
            </Description>
          )}
          <Button
            onClick={() => {
              const track = trackList?.[subcategory]?.employee?.analyzeDocument
              if (track)
                createSegmentTrack({
                  track: track,
                })

              navigate(`../wizard/signing/visualizeContract`, {
                replace: true,
              })
            }}
            variant="primary"
            size="large"
            style={{ alignSelf: "center" }}
          >
            {t("signing.start.buttonLabel")}
          </Button>
        </Container>
      )}

      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}
