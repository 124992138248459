import { Modal } from "../Modal"

interface ModalProps {
  isOpen: boolean
  isSettingMonitoring: boolean
  onClose: () => void
  onSubmit: () => void
}

export const WarningSendSurveyModal = ({
  isOpen,
  isSettingMonitoring,
  onClose,
  onSubmit,
}: ModalProps) => {
  return (
    <Modal
      title="Atenção!"
      subTitle={`Deseja enviar a pesquisa?`}
      description="Após o envio suas respostas não poderão ser editadas."
      onClose={onClose}
      closeButton
      iconStatus="alert"
      open={isOpen}
      iconName="IconAlertCircle"
      footer={{
        closeButton: {
          label: "Não enviar pesquisa",
          onClick: onClose,
          variant: "secondary",
          loading: isSettingMonitoring,
        },
        submitButton: {
          label: "Enviar pesquisa",
          onClick: onSubmit,
          size: "large",
          variant: "secondary-error",
          loading: isSettingMonitoring,
        },
      }}
    />
  )
}
