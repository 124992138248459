import styled, { css } from "styled-components"

export const Container = styled.div`
  margin: 52px 20px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin: 40px 10px;
  }
`

export const StepArea = styled.ul`
  margin-top: 10px;
  position: relative;
  padding-left: 45px;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  &:before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  div:last-child {
    &:before {
      height: 0;
    }
  }
`

export const Step = styled.div<{ status: string }>`
  position: relative;
  counter-increment: list;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
  }

  &:not(:last-child) {
    padding-bottom: 40px;
  }

  &::before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -28px;
    height: 100%;
    width: 10px;
    top: 8px;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 6px;
    left: -33px;
    width: 11px;
    height: 11px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
    background-color: #fff;
  }
  ${({ status }) =>
    status === "done" &&
    css`
      &::before {
        border-left: 1px solid ${({ theme }) => theme.colors.primary};
      }
      &::after {
        color: #fff;
        text-align: center;
        border: 1px solid ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.primary};
      }
    `}

  ${({ status }) =>
    status === "current" &&
    css`
      &::before {
        border-left: 1px solid ${({ theme }) => theme.colors.primary};
        border-style: dotted;
      }
      &::after {
        padding-top: 1px;
        top: 6px;
        left: -33px;
        width: 11px;
        height: 11px;
        font-size: 14px;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
        background-color: white;
      }
    `}
    ${({ status }) =>
    status === "notDone" &&
    css`
      &::before {
        border-left: 1px solid ${({ theme }) => theme.colors.primary};
        border-style: dotted;
      }
      &::after {
        padding-top: 1px;
        top: 6px;
        left: -33px;
        width: 11px;
        height: 11px;
        font-size: 14px;
        text-align: center;
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
        background-color: white;
      }
    `}
`
