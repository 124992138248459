import styled from "styled-components"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { ModalStatus } from "./types"

const getStylesByStatus = (status: ModalStatus) => {
  if (status === "alert")
    return {
      color: "#C96C01",
      backgroundColor: "#FFECD6",
    }

  return {
    color: "var(--color-primary)",
    backgroundColor: "var(--color-secondary-95)",
  }
}

const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  position: absolute;
  right: 12px;
  top: 12px;
  border: 1px solid var(--color-neutral-80);
  cursor: pointer;
`

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const IconContainer = styled.div<{ status?: ModalStatus }>`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ status }) =>
    getStylesByStatus(status || "info")?.backgroundColor};
`

const MainIcon = styled(Icons)<{ status?: ModalStatus }>`
  fill: transparent !important;
  color: ${({ status }) => getStylesByStatus(status || "info")?.color};
`

const Title = styled(Typography)<{ status?: ModalStatus }>`
  color: ${({ status }) => getStylesByStatus(status || "info")?.color};
  font-weight: 700 !important;
`

const SubTitle = styled(Typography)`
  color: var(--color-neutral-20);
  font-weight: 700 !important;
`

const Description = styled(Typography)`
  color: var(--color-neutral-50);
  font-weight: 400 !important;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 72px;
  border-top: 1px solid #ebe6e9;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

export {
  Body,
  IconContainer,
  Title,
  SubTitle,
  Description,
  Footer,
  CloseButton,
  MainIcon,
}
