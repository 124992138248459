import { Checkbox, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 82px 112px 48px 112px;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 80px;
    flex-direction: column;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 60px 40px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    padding: 30px 15px;
  }
`

const LeftContainer = styled.div`
  margin: 54px 92px 0 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-right: 40px;
  }
`

const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

const StepTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 167px;
  margin: 0;
  padding: 0;
  margin-bottom: 22px;
`

const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  max-width: 348px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    justify-content: center;
  }
`

const StyledCheckbox = styled(Checkbox)`
  && {
    &.Mui-checked {
      color: ${({ theme }) => theme?.colors?.primary};
    }
  }
`

const CheckBoxArea = styled.div`
  display: flex;
  align-items: center;

  u {
    cursor: pointer;
  }
`

const HelperText = styled(Typography)`
  margin: 6px 0 0 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    stroke: ${({ theme }) => theme.colors.feedback.error.pure};
    fill: transparent;
    margin-right: 6px;
  }
`

export {
  Container,
  LeftContainer,
  RightContainer,
  StepTitle,
  SubTitle,
  ButtonContainer,
  StyledCheckbox,
  CheckBoxArea,
  HelperText,
}
