import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { TextField } from "@mui/material"
import styled from "styled-components"

const StyledContainer = styled.div`
  width: 100%;
`

const StyledTextField = styled(TextField)`
  &.MuiTextField-root {
    div.MuiInputBase-root {
      div.MuiSelect-select {
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 8px;
      }
    }

    div.MuiFilledInput-root {
      border: 2px solid ${({ theme }) => theme.colors.neutral.light.light4};
      border-radius: 8px;
      background: #fff;

      &:before,
      &:after {
        content: none;
      }
    }

    div.Mui-active {
      border: 2px solid ${({ theme }) => theme.colors.primary};
    }

    div.Mui-focused {
      border: 2px solid ${({ theme }) => theme.colors.primary};
    }

    div.Mui-error {
      background: ${({ theme }) => theme.colors.feedback.error.light1};
      border: 2px solid ${({ theme }) => theme.colors.feedback.error.pure};
    }

    div.Mui-disabled {
      background: ${({ theme }) => theme.colors.neutral.light.light1};
      border: 2px solid transparent;
      border-radius: 8px;
    }

    input {
      color: ${({ theme }) => theme.colors.neutral.dark.dark3};
    }

    label {
      font-family: Nunito, sans-serif;
      font-weight: 600;
    }

    label.Mui-focused {
      color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    }
    label.Mui-error {
      color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    }
  }
`

const StyledText = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export { StyledContainer, StyledTextField, StyledText }
