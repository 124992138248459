import { useContext, useEffect, useState } from "react"
import { Content, Start } from "./screens"
import { Context } from "src/context"
import { trpc } from "src/api/trpc"
import {
  AdmissionalExamStatus,
  Candidate,
  HiringCard,
  HiringLimitDateEnum,
} from "server/src/types"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import dispatchToast from "@Utils/dispatchToast"
import { Loader } from "@flash-tecnologia/hros-web-ui-v2"

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f8f6f8;
  overflow: hidden;
`

const LoadingArea = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

enum StepEnum {
  initial,
  content,
}

export const AdmissionExam = () => {
  const [step, setStep] = useState<StepEnum>(StepEnum.initial)

  const { cardId, candidateId, token } = useContext(Context)
  const navigate = useNavigate()

  const { data, isLoading } = trpc.wizard.getAdmissionalExamData.useQuery(
    {
      cardId,
      candidateId,
    },
    {
      enabled: !!cardId && !!candidateId,
      onSuccess: ({ card }) => {
        const status = card.admissionalExam?.status
        if (!status) {
          navigate("/wizard/invalid")
          return
        }
        if (
          ![AdmissionalExamStatus.sent, AdmissionalExamStatus.resent].includes(
            status
          )
        ) {
          navigate("/wizard/finished?type=aso")
          return
        }

        const emailLink = card.emailLink.find(
          (emailLink) => emailLink.token && token
        )
        if (emailLink?.expired) {
          navigate("/wizard/invalid")
          return
        }

        const limitDate = card?.limitDates.find((ld) =>
          [HiringLimitDateEnum.aso_Ctl, HiringLimitDateEnum.aso_Estag].includes(
            ld.step
          )
        )
        if (
          !limitDate ||
          (limitDate && new Date() > new Date(limitDate.date))
        ) {
          navigate(`../wizard/invalid`, { replace: true })
        }
      },
      onError: (_err) => {
        dispatchToast({
          content: "Algo aconteceu em buscar os dados do colaborador",
          type: "warning",
        })
        navigate("/wizard/invalid")
      },
    }
  )

  useEffect(() => {
    if (data?.card) {
      const limitDate = data?.card?.limitDates.find((ld) =>
        ld.step.includes("aso")
      )

      if (!limitDate && data.card.admissionalExam?.status === "resent") {
        return
      }

      if (!limitDate || (limitDate && new Date() > new Date(limitDate.date))) {
        navigate(`../wizard/invalid`, { replace: true })
        return
      }
    }
  }, [data, navigate])

  if (!data || isLoading) {
    return (
      <MainContainer>
        <LoadingArea>
          <Loader variant="primary" size="large" />
        </LoadingArea>
      </MainContainer>
    )
  }

  switch (step) {
    case StepEnum.initial:
      return (
        <Start
          status={data.card.status || "sent"}
          handleNextStep={() => setStep(StepEnum.content)}
        />
      )
    case StepEnum.content:
      return (
        <Content card={data.card as HiringCard} candidate={data.candidate} />
      )
  }
}
