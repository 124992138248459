import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import {
  CollapseContainer,
  FlexSpaceBetween,
  OptionalContainer,
  OptionalSubContainer,
  IconContainer,
  EditIconContainer,
  IconTrashContainer,
} from "./styles"

const AccordionHeader = ({
  optional,
  subLabel = "",
  label,
  hasExpanded,
  onClick,
  hasEditIcon = false,
  hasDeleteIcon = false,
  onEditClick,
  onDeleteClick,
}: {
  optional: boolean
  subLabel: string
  label: string
  hasExpanded: boolean
  onClick: any
  hasEditIcon?: boolean
  hasDeleteIcon?: boolean
  onEditClick?: any
  onDeleteClick?: any
}) => {
  return (
    <CollapseContainer expanded={hasExpanded} onClick={onClick}>
      <FlexSpaceBetween>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
          }}
        >
          <OptionalContainer>
            {optional ? (
              <OptionalSubContainer>
                <span>Opcional</span>
              </OptionalSubContainer>
            ) : (
              <></>
            )}
            <Typography variant="headline8" style={{ color: "#55444F" }}>
              {label}
            </Typography>
          </OptionalContainer>

          <Typography
            variant="body3"
            style={{ marginTop: "5px", color: "#856F7E" }}
          >
            {subLabel}
          </Typography>
        </div>

        <div>
          {hasEditIcon ? (
            <EditIconContainer onClick={onEditClick}>
              <Icons name="IconPencil" size={24} fill={"transparent"} />
            </EditIconContainer>
          ) : (
            <></>
          )}
          {hasDeleteIcon ? (
            <IconTrashContainer onClick={onDeleteClick}>
              <Icons name="IconTrash" size={24} fill={"transparent"} />
            </IconTrashContainer>
          ) : (
            <></>
          )}
          <IconContainer>
            <Icons
              name="IconChevronUp"
              size={24}
              style={{
                transform: `rotate(${!hasExpanded ? "0deg" : "180deg"})`,
                fill: "transparent",
              }}
            />
          </IconContainer>
        </div>
      </FlexSpaceBetween>
    </CollapseContainer>
  )
}

export { AccordionHeader }
