import { Icons, Loader, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"

import { StyledFooterBar, StyledButton } from "./style"

const FooterBar = ({
  isLoading,
  currentPage,
  totalPages,
  nextHandleClick,
  nextLabel = "Continuar",
}: {
  isLoading: boolean
  currentPage: number
  totalPages: number
  nextHandleClick: any
  nextLabel?: string
}) => {
  const theme = useTheme()

  return (
    <StyledFooterBar>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Typography variant="headline8" style={{ color: "#53464F" }}>
            Página{" "}
            <span style={{ color: theme.colors.primary }}>
              {currentPage + 1}
            </span>{" "}
            de <span style={{ color: theme.colors.primary }}>{totalPages}</span>
          </Typography>
        </div>
        <div>
          <StyledButton
            variant="primary"
            size="medium"
            onClick={() => nextHandleClick && nextHandleClick()}
          >
            {isLoading ? (
              <Loader size="medium" variant="secondary" />
            ) : (
              <>
                <Typography variant="body3" style={{ fontWeight: 700 }}>
                  {nextLabel}
                </Typography>
                <Icons
                  name="IconArrowRight"
                  style={{
                    fill: "transparent",
                    color: "#fff",
                    marginLeft: "8px",
                  }}
                />
              </>
            )}
          </StyledButton>
        </div>
      </div>
    </StyledFooterBar>
  )
}

export default FooterBar
