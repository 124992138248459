import styled from "styled-components"

export const MainContainer = styled.div`
  height: 100%;
  background: #f8f6f8;
`

export const Container = styled.div`
  background: #f8f6f8;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    border: none;
  }
`

export const ModalContainer = styled.div`
  background: #fff;
  padding: 80px 40px;
  margin: 0px 40px;
  border-radius: 8px;
  height: fit-content;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    padding: 80px 120px;
  }
`

export const IconContainer = styled.div`
  border-radius: 50%;
  width: 64px;
  height: 64px;
  background: ${({ theme }) => theme.colors.secondary[95]};
  color: ${({ theme }) => theme.colors.secondary[50]};

  position: absolute;
  top: -32px;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: 32px;
    height: 32px;
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100px;
    height: 100px;
    top: -50px;

    > svg {
      width: 64px;
      height: 64px;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;

  > div,
  > div > button {
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 300px;

    > div {
      width: fit-content;
    }
  }
`
