import {
  Candidate,
  Contract,
  HiringCard,
  ResignationCard,
} from "server/src/types"
import { Content } from "./Content"
import { InstitutionContent as InstitutionContent } from "./InstitutionContent"
import { WitnessContent as WitnessContent } from "./WitnessContent"
import { Context } from "src/context"
import { useContext } from "react"

const StartSigningPage = ({
  card,
  contract,
}: {
  card: HiringCard | ResignationCard
  contract: Contract
}) => {
  const { type } = useContext(Context)

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {type === "institution" ? (
        <InstitutionContent card={card} contract={contract} />
      ) : type === "witness" ? (
        <WitnessContent card={card} contract={contract} />
      ) : (
        <Content card={card} contract={contract} />
      )}
    </div>
  )
}

export default StartSigningPage
