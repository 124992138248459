import { Tab, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { Container, LeftContainer, RightContainer } from "../styles"
import { Texting } from "../shared/Texting"
import { Drawing } from "../shared/Drawing"
import { createSegmentTrack } from "@Utils/segment"
import { useNavigate } from "react-router-dom"

export const Desktop = ({
  attachments,
  isLoading,
  addDrawing,
  addTextField,
  updateDrawing,
  updateText,
  track,
}) => {
  const navigate = useNavigate()

  const filteredDrawing = attachments.filter((a) => a.type === "drawing") || []
  const filteredTexting = attachments.filter((a) => a.type === "text") || []

  const lastDrawing = filteredDrawing.pop() || {}
  const lastTexting = filteredTexting.pop() || {}

  const dismiss = () =>
    navigate(`../wizard/signing/confirmData`, {
      replace: true,
    })

  return (
    <Container>
      <LeftContainer>
        <Typography variant="headline7" variantColor="#F20D7A">
          Assinar contrato
        </Typography>
        <Typography
          variant="body3"
          variantColor="#83727D"
          style={{
            marginTop: "24px",
          }}
        >
          Assine o contrato para finalizar o processo.
        </Typography>
      </LeftContainer>

      <RightContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="headline6" style={{ marginBottom: 24 }}>
            Insira sua assinatura abaixo
          </Typography>

          <Tab
            defaultTab={0}
            tabItens={[
              {
                label: "Digitar",
                component: (
                  <Texting
                    dismiss={dismiss}
                    update={updateText}
                    confirm={addTextField}
                    lastTexting={lastTexting}
                    loading={isLoading}
                    segmentTrack={track}
                  />
                ),
              },
              {
                label: "Desenhar",
                component: (
                  <Drawing
                    update={updateDrawing}
                    dismiss={dismiss}
                    confirm={addDrawing}
                    lastDrawing={lastDrawing}
                    loading={isLoading}
                    segmentTrack={track}
                  />
                ),
              },
            ]}
            onTabChanged={(tab) => {
              enum tabs {
                "text" = 0,
                "drawing" = 1,
              }

              const clickedTabSegmentList = {
                [tabs.drawing]: track?.signContractDraw,
                [tabs.text]: track?.signContractDraw,
              }

              if (clickedTabSegmentList)
                createSegmentTrack({ track: clickedTabSegmentList[tab] })
            }}
          />
        </div>
      </RightContainer>
    </Container>
  )
}
