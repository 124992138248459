import {
  Accordion,
  AccordionHeader,
  PreviewField,
  RenderPreviewField,
  ValidationAccordion,
} from "@Components"
import { useState } from "react"
import {
  ConfirmationDocumentsWithValue,
  FieldValue,
  HiringCard,
} from "server/src/types"
import {
  AccordionChildren,
  ChildrenContainer,
  PreviewDocContainer,
  PreviewDocWithoutContainer,
  PreviewImageContainer,
  PreviewTextContainer,
  StyledPOneLine,
} from "./styles"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import Download from "../../../../assets/download.svg"
import { EditDocumentsModal } from "./EditModal"

type ModalEdit = {
  isOpen: boolean
  document?: ConfirmationDocumentsWithValue
}

const CandidateDocuments = ({
  card,
  documents,
}: {
  card: HiringCard
  documents: ConfirmationDocumentsWithValue[]
}) => {
  const [editModal, setEditModal] = useState<ModalEdit>({
    isOpen: false,
  })
  const [expanded, setExpanded] = useState<number | null>(null)

  const renderChildren = (fields: FieldValue[]) => {
    const filterFileFields = fields.filter((f) => f.fieldType === "file") || []
    const filterOtherFields = fields.filter((f) => f.fieldType !== "file") || []

    return (
      <AccordionChildren>
        {filterFileFields.length ? (
          <>
            <Typography
              variant="body3"
              style={{ color: "#856F7E", fontWeight: 700 }}
            >
              Fotos do documento
            </Typography>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {filterFileFields.map((f, index) => {
                const { value = "", key = "" } = f?.value || {}

                return (
                  <ChildrenContainer key={`candidateDataFields${index}`}>
                    {value ? (
                      <PreviewDocContainer
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(value, "_blank")
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <PreviewImageContainer url={value} />
                          <PreviewTextContainer style={{ marginLeft: "10px" }}>
                            <StyledPOneLine>
                              <Typography variant="body3">
                                {f.placeholder || ""}
                              </Typography>
                            </StyledPOneLine>
                          </PreviewTextContainer>
                        </div>
                        <Download />
                      </PreviewDocContainer>
                    ) : (
                      <PreviewDocWithoutContainer>
                        <div style={{ display: "flex" }}>
                          <StyledPOneLine>
                            <Typography variant="body4">
                              Documento não enviado
                            </Typography>
                          </StyledPOneLine>
                        </div>
                      </PreviewDocWithoutContainer>
                    )}
                  </ChildrenContainer>
                )
              })}
            </div>
          </>
        ) : (
          <></>
        )}

        {filterOtherFields.length ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {filterOtherFields.map((f, idx) => (
              <RenderPreviewField
                key={idx}
                value={f.value}
                component={f.component}
                label={f.placeholder}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </AccordionChildren>
    )
  }

  return (
    <>
      {documents
        .filter((section) => section.enabled)
        .map((document, idx) => {
          const isRequired = document.fields.some(
            (field) => field.fieldRule?.required
          )

          return (
            <ValidationAccordion
              key={idx}
              title={document.title}
              description={document.description}
              required={isRequired}
              status={isRequired ? undefined : "optional"}
              handleEdit={async (e) => {
                e.stopPropagation()
                setEditModal({
                  isOpen: true,
                  document: document,
                })
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 24,
                }}
              >
                {document.fields.map((field, idx) => {
                  return <PreviewField key={idx} field={field} />
                })}
              </div>
            </ValidationAccordion>
          )
        })}

      {editModal.isOpen && editModal.document && (
        <EditDocumentsModal
          isOpen={editModal.isOpen}
          document={editModal.document}
          card={card}
          handleClose={() => {
            setEditModal({
              isOpen: false,
            })
          }}
        />
      )}
    </>
  )
}

export default CandidateDocuments
