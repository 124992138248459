import { TextField } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

export const AccordionChildrenItems = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

export const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

export const PreviewDocContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
  cursor: pointer;
`

export const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

export const IconContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #f6f3f6;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
  }
`

export const RequiredLabel = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`
