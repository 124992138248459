import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Context } from "../../../context"

import { Container, Element, ElementText } from "./styles"
import { Candidate } from "server/src/types"

type LabelData = {
  basicData: string
  candidate: string
  dependents: string
  confirmation: string
}

type LabelList = { [hiringFlowId: string]: LabelData }

export const BreadCrumbs = ({ candidate }: { candidate: Candidate }) => {
  const { subcategory } = useContext(Context)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const navigate = useNavigate()
  const location = useLocation()
  const isSmallScreen = useMediaQuery("(max-width: 1010px)")

  const labelList: LabelList = {
    ["pj"]: {
      basicData: t("breadcrumb.pj.firstStepLabel"),
      candidate: isSmallScreen
        ? t("breadcrumb.pj.secondStepSmallLabel")
        : t("breadcrumb.pj.secondStepLabel"),
      dependents: isSmallScreen
        ? t("breadcrumb.pj.thirdStepSmallLabel")
        : t("breadcrumb.pj.thirdStepLabel"),
      confirmation: t("breadcrumb.pj.fourthStepLabel"),
    },
    ["clt"]: {
      basicData: t("breadcrumb.clt.firstStepLabel"),
      candidate: isSmallScreen
        ? t("breadcrumb.clt.secondStepSmallLabel")
        : t("breadcrumb.clt.secondStepLabel"),
      dependents: isSmallScreen
        ? t("breadcrumb.clt.thirdStepSmallLabel")
        : t("breadcrumb.clt.thirdStepLabel"),
      confirmation: t("breadcrumb.clt.fourthStepLabel"),
    },
    ["internship"]: {
      basicData: t("breadcrumb.internship.firstStepLabel"),
      candidate: isSmallScreen
        ? t("breadcrumb.internship.secondStepSmallLabel")
        : t("breadcrumb.internship.secondStepLabel"),
      dependents: isSmallScreen
        ? t("breadcrumb.internship.thirdStepSmallLabel")
        : t("breadcrumb.internship.thirdStepLabel"),
      confirmation: t("breadcrumb.internship.fourthStepLabel"),
    },
  }

  const hiringType = subcategory || "clt"
  const label = labelList[hiringType]

  const items = [
    {
      title: label.basicData,
      path: `wizard/hiring/basic-data`,
    },
    {
      title: label.candidate,
      path: `wizard/hiring/candidate`,
    },
    {
      title: label.dependents,
      path: `wizard/hiring/dependents`,
    },
    {
      title: label.confirmation,
      path: `wizard/hiring/confirmation`,
    },
  ]

  const separator = ">"

  let status = "done"

  return (
    <Container>
      {items.map((item, index) => {
        const path = location?.pathname

        if (path === `/${item?.path}`) status = "current"
        if (path !== `/${item?.path}` && status === "current")
          status = "notDone"

        return (
          <Element key={index}>
            <ElementText status={status}>{item.title}</ElementText>

            {index !== items.length - 1 && (
              <div style={{ padding: "0px 15px" }}>{separator}</div>
            )}
          </Element>
        )
      })}
    </Container>
  )
}
