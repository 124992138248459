import {
  ModalPreviewInstruction,
  RenderField,
  ValidationAccordion,
} from "@Components"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { useContext, useState, useMemo } from "react"
import {
  Component,
  ConfirmationDependent,
  ConfirmationDocumentsWithValue,
  FieldRule,
  FieldType,
  HiringCard,
} from "server/src/types"
import { ChildrenContainer, DependentsContainer } from "./styles"
import { Context } from "src/context"
import { RemoveDependentModal } from "./RemoveDependentModal"
import { trpc } from "src/api/trpc"
import { cloneDeep } from "lodash-es"
import { mandatoryFieldsMock } from "@Utils/mocks"
import { convertMandatoryFieldToFieldValue } from "@Utils/index"
import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"

export type MandatoryField = {
  fieldName: string
  fieldType: FieldType
  placeholder: string
  component: Component
  fieldRule?: FieldRule
  options?: string[]
}

interface RenderDependentsProps {
  dependents: ConfirmationDependent[]
  card: HiringCard
  fieldErrors: any
  showErrors: boolean
  setDependents: any
}

const RenderDependents = ({
  dependents,
  card,
  fieldErrors,
  showErrors,
  setDependents,
}: RenderDependentsProps) => {
  const [dependentsDocuments, setDependentsDocuments] =
    useState<ConfirmationDependent[]>(dependents)
  const [modalController, setModalController] = useState({
    isOpen: false,
    dependentId: "",
  })
  const [instructionPreview, setInstructionPreview] = useState<{
    isOpen: boolean
    document?: ConfirmationDocumentsWithValue
  }>({
    isOpen: false,
  })

  const { subcategory } = useContext(Context)
  const { mutateAsync: updateDependentFields } =
    trpc.candidate.updateDependentFields.useMutation()
  const { mutate: updateDependent } =
    trpc.candidate.updateDependent.useMutation()
  const { mutateAsync: processingFile } =
    trpc.helper.processingFile.useMutation()
  const { mutateAsync: validateDocument } =
    trpc.candidate.validateDependentDocument.useMutation()

  const handleMandatoryFields = ({
    dependentId,
    mandatoryField,
    value,
  }: {
    dependentId: string
    mandatoryField: string
    value: any
  }) => {
    setDependentsDocuments((prev) => {
      const clonedDependents = cloneDeep(prev)

      const index = clonedDependents.findIndex(
        (dependent) => dependent._id === dependentId
      )
      if (index === -1) return prev

      clonedDependents[index][mandatoryField] = value
      return clonedDependents
    })

    updateDependent({
      dependentId,
      params: { [mandatoryField]: value },
    })
  }

  const updateDependentsLocal = async ({
    dependentId,
    documentId,
    fieldId,
    sectionId,
    value,
  }) => {
    if (value?.path) {
      const processedFile = await processingFile({ path: value?.path })
      value = { ...value, value: processedFile }
    }

    setDependentsDocuments((prev) => {
      const clonedDependents = cloneDeep(prev)

      const dependentIndex = clonedDependents.findIndex(
        (dependent) => dependent._id === dependentId
      )
      if (dependentIndex === -1) return prev

      const documentIndex = clonedDependents[
        dependentIndex
      ].documents.findIndex((document) => document._id === documentId)
      if (documentIndex === -1) return prev

      const indexField = clonedDependents[dependentIndex].documents[
        documentIndex
      ].fields.findIndex(
        (field) => field.fieldId === fieldId && field.sectionId === sectionId
      )
      if (indexField === -1) return prev

      clonedDependents[dependentIndex].documents[documentIndex].fields[
        indexField
      ].value = value || ""
      if (
        clonedDependents[dependentIndex].documents[documentIndex].validation
          ?.status !== "waiting"
      ) {
        clonedDependents[dependentIndex].documents[documentIndex].validation = {
          sectionId: documentId,
          status: "waiting",
          statusComment: "",
        }
      }
      return clonedDependents
    })
  }

  const handleDocumentField = async ({
    documentId,
    formConfigId,
    extensionId,
    fieldType,
    value,
    fieldSectionId,
    fieldId,
    dependentId,
  }: {
    documentId: string
    formConfigId: string
    extensionId: string
    fieldType: string
    value: any
    fieldSectionId: string
    fieldId: string
    dependentId: string
  }) => {
    setDependents((prev) => {
      if (!prev) return
      const dependents = cloneDeep(prev)

      const indexDep = dependents.findIndex((dep) => dep._id === dependentId)

      if (indexDep === -1) return

      const indexDoc = dependents[indexDep].documents.findIndex((docs) =>
        docs.fields.some(
          (field) =>
            field.sectionId === fieldSectionId && field.fieldId === fieldId
        )
      )
      if (indexDoc === -1) return

      const indexField = dependents[indexDep].documents[
        indexDoc
      ].fields.findIndex((field) => field.fieldId === fieldId)
      if (indexField === -1) return

      const parsedValue = value
      dependents[indexDep].documents[indexDoc].fields[indexField].value =
        parsedValue || ""
      if (
        dependents[indexDep].documents[indexDoc].validation?.status !==
        "waiting"
      ) {
        dependents[indexDep].documents[indexDoc].validation = {
          sectionId: documentId,
          status: "waiting",
          statusComment: "",
        }
      }
      return dependents
    })

    await updateDependentsLocal({
      dependentId,
      documentId,
      fieldId,
      sectionId: fieldSectionId,
      value,
    })

    await updateDependentFields({
      dependentId,
      params: {
        values: [
          {
            fieldId: fieldId,
            type: fieldType,
            sectionId: fieldSectionId,
            value: value || "",
            expirationDate: null,
          },
        ],
      },
    })

    await validateDocument({
      dependentId,
      sectionId: documentId,
      status: "waiting",
      statusComment: "",
    })
  }

  const mandatoryFields: MandatoryField[] = useMemo(
    () => mandatoryFieldsMock,
    []
  )

  const renderMandatoryFields = (dependent: ConfirmationDependent) => {
    return (
      <ValidationAccordion
        title={"Dados básicos"}
        description={
          "Preencha as informações abaixo de acordo com os dados do dependente."
        }
        required={true}
        status={"waiting"}
      >
        <ChildrenContainer>
          {mandatoryFields.map((field, idx) => {
            const convertedField = convertMandatoryFieldToFieldValue(
              field,
              dependent[field.fieldName],
              card.candidateId
            )

            return (
              <RenderField
                key={idx}
                field={convertedField}
                handleChange={async (value) => {
                  if (value === dependent[field.fieldName].value) return
                  handleMandatoryFields({
                    dependentId: dependent._id,
                    mandatoryField: field.fieldName,
                    value,
                  })
                }}
              />
            )
          })}
        </ChildrenContainer>
      </ValidationAccordion>
    )
  }

  return (
    <DependentsContainer>
      {dependentsDocuments.map((dependent, index) => {
        let status: "waiting" | "declined" | "approved" = "waiting"

        if (dependent.documents?.length > 0) {
          const anyDocDeclined = dependent.documents?.some(
            (doc) => doc.validation?.status === "declined"
          )
          if (anyDocDeclined) status = "declined"

          const allDocsApproved = dependent.documents?.every(
            (doc) => doc.validation?.status === "approved"
          )
          if (allDocsApproved) status = "approved"
        }

        let showErrorMessage = 0
        if (fieldErrors?.length > 0) {
          fieldErrors[index]?.documents.forEach((d) => {
            showErrorMessage += d.fields.length
          })
        }
        return (
          <ValidationAccordion
            key={`dependent_${index}_${new Date().toISOString}`}
            title={dependent.name}
            description={
              "Preencha as informações abaixo de acordo com os dados do dependente."
            }
            required={false}
            status={status}
            handleDelete={async (e) => {
              e.stopPropagation()
              const track =
                trackList?.[subcategory]?.employee?.sendDocDependentDelete
              if (track)
                createSegmentTrack({
                  track: track,
                })

              setModalController({ isOpen: true, dependentId: dependent._id })
            }}
            fieldsErrors={
              showErrors && showErrorMessage > 0
                ? `${showErrorMessage} campo${
                    showErrorMessage > 1 ? "s" : ""
                  } obrigatório${showErrorMessage > 1 ? "s" : ""} não fo${
                    showErrorMessage > 1 ? "ram" : "i"
                  } preenchido${showErrorMessage > 1 ? "s" : ""}!`
                : ""
            }
            errorBorder={showErrors && showErrorMessage > 0}
          >
            <ChildrenContainer>
              {renderMandatoryFields(dependent)}

              {dependent.documents
                .filter((doc) => doc.enabled)
                .map((doc, idx) => {
                  const isRequired = doc.fields.some(
                    (field) => field.fieldRule?.required || false
                  )

                  const status = doc.validation?.status || "waiting"
                  const refusedReason =
                    doc.validation?.status === "declined"
                      ? doc.validation?.statusComment
                      : undefined

                  let showErrorMessage = 0
                  if (fieldErrors?.length > 0) {
                    showErrorMessage =
                      fieldErrors[index]?.documents[idx].fields.length
                  }

                  return (
                    <ValidationAccordion
                      key={idx}
                      title={doc.title}
                      description={doc.description}
                      required={isRequired}
                      status={status}
                      refusedReason={refusedReason}
                      fieldsErrors={
                        showErrors && showErrorMessage > 0
                          ? `${showErrorMessage} campo${
                              showErrorMessage > 1 ? "s" : ""
                            } obrigatório${
                              showErrorMessage > 1 ? "s" : ""
                            } não fo${
                              showErrorMessage > 1 ? "ram" : "i"
                            } preenchido${showErrorMessage > 1 ? "s" : ""}!`
                          : ""
                      }
                      errorBorder={showErrors && showErrorMessage > 0}
                    >
                      {doc.fillInstruction?.html && (
                        <LinkButton
                          variant="primary"
                          style={{
                            fontSize: 14,
                            marginBottom: 24,
                          }}
                          onClick={() =>
                            setInstructionPreview({
                              isOpen: true,
                              document: doc,
                            })
                          }
                        >
                          Como encontrar ou preencher esse documento?
                          <Icons
                            fill="transparent"
                            name="IconZoomQuestion"
                            size={14}
                          />
                        </LinkButton>
                      )}

                      <ChildrenContainer>
                        {doc.fields.map((field, index) => (
                          <RenderField
                            key={index}
                            field={field}
                            candidateId={card.candidateId}
                            companyId={card.companyId}
                            handleChange={async (value) => {
                              if (field.value && value === field.value) return
                              await handleDocumentField({
                                dependentId: dependent._id,
                                documentId: doc._id,
                                formConfigId: card.formConfigId,
                                extensionId: card.formExtensionId,
                                fieldId: field.fieldId,
                                fieldSectionId: field.sectionId,
                                fieldType: field.fieldType,
                                value,
                              })
                            }}
                            showError={showErrors}
                          />
                        ))}
                      </ChildrenContainer>
                    </ValidationAccordion>
                  )
                })}
            </ChildrenContainer>
          </ValidationAccordion>
        )
      })}

      {instructionPreview.isOpen && instructionPreview.document && (
        <ModalPreviewInstruction
          isOpen={instructionPreview.isOpen}
          handleClose={() => setInstructionPreview({ isOpen: false })}
          document={instructionPreview.document}
        />
      )}

      {modalController.isOpen && (
        <RemoveDependentModal
          isOpen={modalController.isOpen}
          candidateId={card.candidateId}
          dependentId={modalController.dependentId}
          handleClose={() =>
            setModalController({
              isOpen: false,
              dependentId: "",
            })
          }
        />
      )}
    </DependentsContainer>
  )
}

export default RenderDependents
