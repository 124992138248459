import { useState } from "react"
import { PreviewField, ValidationAccordion } from "@Components"
import { FieldValue, HiringCard } from "server/src/types"
import EditCandidateModal from "./EditModal/EditCandidate"

type ModalEdit = {
  isOpen: boolean
  fields?: FieldValue[]
}

const CandidateData = ({
  card,
  basicData,
}: {
  card: HiringCard
  basicData: FieldValue[]
}) => {
  const [editModal, setEditModal] = useState<ModalEdit>({
    isOpen: false,
  })

  return (
    <>
      <ValidationAccordion
        title={"Dados básicos"}
        required={true}
        handleEdit={async (e) => {
          e.stopPropagation()
          setEditModal({
            isOpen: true,
            fields: basicData,
          })
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 24,
          }}
        >
          {basicData.map((field, idx) => {
            return <PreviewField key={idx} field={field} />
          })}
        </div>
      </ValidationAccordion>

      {editModal.isOpen && editModal.fields && (
        <EditCandidateModal
          isOpen={editModal.isOpen}
          card={card}
          fields={editModal.fields}
          handleClose={() => {
            setEditModal({
              isOpen: false,
            })
          }}
        />
      )}
    </>
  )
}

export default CandidateData
