import { PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

export const PhoneMask = (value, countryCode = "BR") => {
  const parsedNumber =
    value.length > 2 ? phoneUtil.parseAndKeepRawInput(value, countryCode) : ""

  const isValid = parsedNumber ? phoneUtil.isValidNumber(parsedNumber) : false

  if (isValid) {
    return phoneUtil.formatInOriginalFormat(parsedNumber, countryCode)
  }

  return value.replace(/\D/g, "")
}

export const validatePhone = (value, countryCode = "BR") => {
  const parsedNumber =
    value.length > 2 ? phoneUtil.parseAndKeepRawInput(value, countryCode) : ""

  const isValid = parsedNumber ? phoneUtil.isValidNumber(parsedNumber) : false

  return isValid
}
