import { shallowEqual } from "@Utils/index"
import { createContext, ReactElement, useState } from "react"

interface TokenData {
  engagementId: string
  cardId: string
  employeeId: string
  candidateId: string
  companyId: string
  name: string
  type: string
  category: "hiring" | "resignation"
  subcategory: string
  token: string
}

interface IContext extends TokenData {
  setToken: (data: TokenData) => void
}

const Context = createContext<IContext>(null!)

const ContextProvider = ({ children }: { children: ReactElement }) => {
  const [tokenData, setTokenData] = useState<TokenData>({
    engagementId: "",
    cardId: "",
    employeeId: "",
    candidateId: "",
    companyId: "",
    name: "",
    type: "",
    category: "hiring",
    subcategory: "",
    token: "",
  })

  const setToken = (token: TokenData) => {
    if (shallowEqual(token, tokenData)) return
    setTokenData(token)
  }

  return (
    <Context.Provider
      value={{
        ...tokenData,
        setToken,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export { ContextProvider, Context }
