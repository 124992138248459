import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Container, Step, StepArea } from "./styles"

export const StepperSigning = ({ onItemClick }: { onItemClick: () => any }) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const navigate = useNavigate()
  const location = useLocation()

  const items = [
    {
      title: t("breadcrumbSigning.firstStepLabel"),
      path: `wizard/signing/visualizeContract`,
    },
    {
      title: t("breadcrumbSigning.secondStepLabel"),
      path: `wizard/signing/confirmData`,
    },
    {
      title: t("breadcrumbSigning.thirdStepLabel"),
      path: `wizard/signing/signData`,
    },
  ]

  let status = "done"

  return (
    <Container>
      <StepArea>
        {items?.map(({ title, path }, index) => {
          if (location?.pathname === `/${path}`) status = "current"
          if (location?.pathname !== `/${path}` && status === "current")
            status = "notDone"
          return (
            <Step
              onClick={() => {
                navigate(`../${path}`, { replace: true })
                onItemClick()
              }}
              status={status}
              key={index}
            >
              {title}
            </Step>
          )
        })}
      </StepArea>
    </Container>
  )
}
