import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import Logo from "../../../assets/logo_flash.svg"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  Avatar,
  LogoArea,
  TextArea,
} from "./styles"

const Desktop = ({ type }: { type: "invalid" | "expired" | "corrupted" }) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const texts = {
    ["invalid"]: {
      title: "Não encontramos a página que você está tentando acessar.",
      subTitle: "Verifique se a URL acessada está correta.",
    },
    ["expired"]: {
      title: "O prazo para acesso a esse link expirou",
      subTitle: "Solicite outro acesso ao seu RH.",
    },
    ["corrupted"]: {
      title: "Houve um erro de leitura no arquivo do contrato",
      subTitle:
        "O arquivo atual está com problemas de leitura, você precisa solicitar um novo contrato para o seu RH.",
    },
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Avatar>
          <Icons
            name="IconMoodSad"
            style={{
              width: "70px",
              height: "70px",
              fill: "transparent",
              color: "#fff",
            }}
          />
        </Avatar>
        <ProposalTitle variant="headline7">{texts[type].title}</ProposalTitle>
        <ProposalSubtitle variant="body3">
          {texts[type].subTitle}
        </ProposalSubtitle>
      </Container>
      <LogoArea>
        <TextArea variant={"caption"}>
          {t("proposal.start.developedBy")}
        </TextArea>
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </div>
  )
}

export default Desktop
