import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  Description,
  Avatar,
  LogoArea,
  TextArea,
} from "./styles"

import Logo from "../../../../../assets/logo_flash.svg"

const Desktop = () => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <Avatar>
          <Icons
            name="IconMoodHappy"
            style={{
              width: "70px",
              height: "70px",
              fill: "transparent",
              color: "#fff",
            }}
          />
        </Avatar>
        <ProposalTitle variant="headline6">
          {t("proposal.accepted.title")}
        </ProposalTitle>
        <ProposalSubtitle variant="body3">
          {t("proposal.accepted.subTitle")}
        </ProposalSubtitle>
        <Description variant="body3">
          {t("proposal.accepted.description")}
        </Description>
      </Container>
      <LogoArea>
        <TextArea variant={"caption"}>
          {t("proposal.start.developedBy")}
        </TextArea>
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </div>
  )
}

export default Desktop
