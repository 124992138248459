import { useMemo } from "react"
import Mobile from "./Mobile"
import Desktop from "./Desktop"

import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Company } from "server/src/types"

const Header = ({ company }: { company: Company }) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const HeaderRender = useMemo(
    () =>
      isMobile ? <Mobile company={company} /> : <Desktop company={company} />,
    [isMobile]
  )

  return HeaderRender
}

export default Header
