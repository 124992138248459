import React from "react"
import ReactDOM from "react-dom"
import singleSpaReact from "single-spa-react"
import Root from "./root.component"

import { ErrorBoundary } from "./utils/ErrorBoundary"

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundaryClass: ErrorBoundary as any,
  // remove any after migrating to utility package
})

export const { bootstrap, mount, unmount } = lifecycles
