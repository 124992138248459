export default {
  translations: {
    page: {
      breadcrumbSigning: {
        firstStepLabel: "Visualize contract",
        secondStepLabel: "Confirm datas",
        secondStepSmallLabel: "Confirm datas",
        thirdStepLabel: "Sign contract",
        thirdStepSmallLabel: "Sign contract",
      },
      breadcrumb: {
        firstStepLabel: "Basic data",
        secondStepLabel: "Personal and legal documents",
        secondStepSmallLabel: "Personal docs",
        thirdStepLabel: "Dependent documents",
        thirdStepSmallLabel: "Dependents docs",
        fourthStepLabel: "Confirmation",
      },
      basicDataStep: {
        titleLabel: "Candidate basic data",
        cpfError: "Please input a valid CPF",
      },
      dependentsStep: {
        titleLabel: "Dependent documents",
      },
      candidateStep: {
        titleLabel: "Candidate documents",
      },
      confirmationStep: {
        titleLabel: "Check your data and documents",
      },
    },
  },
}
