import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { createSegmentTrack } from "@Utils/segment"
import { Drawing } from "../shared/Drawing"
import { Modal, Tab } from "@flash-tecnologia/hros-web-ui-v2"
import { Texting } from "../shared/Texting"

export const Mobile = ({
  attachments,
  isLoading,
  addDrawing,
  addTextField,
  updateDrawing,
  updateText,
  track,
}) => {
  const [openModal, setOpenModal] = useState<{
    isOpen: boolean
    name: string
    info?: any
  }>({
    isOpen: false,
    name: "",
  })

  const navigate = useNavigate()

  useEffect(() => {
    if (!attachments?.length) {
      setOpenModal({ name: "attachmentModal", isOpen: true })
    }
  }, [])

  const filteredDrawing = attachments.filter((a) => a.type === "drawing") || []
  const filteredTexting = attachments.filter((a) => a.type === "text") || []

  const lastDrawing = filteredDrawing.pop() || {}
  const lastTexting = filteredTexting.pop() || {}

  const dismiss = () => {
    setOpenModal({
      name: "attachmentModal",
      isOpen: false,
    })
    navigate(`../wizard/signing/confirmData`, {
      replace: true,
    })
  }

  return (
    <Modal.Root
      size="full"
      style={{
        height: "100%",
      }}
      onClose={dismiss}
      open={openModal.name === "attachmentModal" && openModal.isOpen}
    >
      <Modal.Content
        style={{
          padding: "0px 15px",
        }}
      >
        <Tab
          defaultTab={
            openModal.info?.type === "text"
              ? 0
              : openModal.info?.type === "drawing"
              ? 1
              : 0
          }
          tabItens={[
            {
              label: "Digitar",
              component: (
                <Texting
                  dismiss={dismiss}
                  update={updateText}
                  confirm={addTextField}
                  lastTexting={lastTexting}
                  loading={isLoading}
                  segmentTrack={track}
                  modalInfo={openModal.info}
                />
              ),
            },
            {
              label: "Desenhar",
              component: (
                <Drawing
                  update={updateDrawing}
                  dismiss={dismiss}
                  confirm={addDrawing}
                  lastDrawing={lastDrawing}
                  loading={isLoading}
                  segmentTrack={track}
                  modalInfo={openModal.info}
                />
              ),
            },
          ]}
          onTabChanged={(tab) => {
            enum tabs {
              "draw" = 0,
              "text" = 1,
            }

            const clickedTabSegmentList = {
              [tabs.draw]: track?.signContractDraw,
              [tabs.text]: track?.signContractDraw,
            }

            if (clickedTabSegmentList)
              createSegmentTrack({ track: clickedTabSegmentList[tab] })
          }}
        />
      </Modal.Content>
    </Modal.Root>
  )
}
