import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
`

export { Container, ContentWrapper }
