import { Typography, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Logo } from "../Logo"
import styled from "styled-components"

export const Container = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  background: ${({ isMobile }) => (isMobile ? "#f8f6f8" : "#fff")};
  padding: ${({ isMobile }) => (isMobile ? "14px 24px" : "20px 43px")};
`

export const Navbar = () => {
  const isMobile = useMediaQuery("(max-width: 576px)")

  return (
    <Container isMobile={isMobile}>
      <Logo />
      <Typography
        variant="body3"
        variantColor="#3B2E37"
        style={{
          fontWeight: 700,
          marginLeft: 12,
        }}
      >
        Flash Benefícios
      </Typography>
    </Container>
  )
}
