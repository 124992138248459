import { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"

import { Container, Element, ElementText } from "./styles"
import { getFromSS } from "@flash-hros/utility"
import { Context } from "../../../context"

export const BreadCrumbsSigning = () => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const navigate = useNavigate()
  const location = useLocation()
  const isSmallScreen = useMediaQuery("(max-width: 1010px)")

  const items = [
    {
      title: t("breadcrumbSigning.firstStepLabel"),
      path: `wizard/signing/visualizeContract`,
    },
    {
      title: isSmallScreen
        ? t("breadcrumbSigning.secondStepSmallLabel")
        : t("breadcrumbSigning.secondStepLabel"),
      path: `wizard/signing/confirmData`,
    },
    {
      title: isSmallScreen
        ? t("breadcrumbSigning.thirdStepSmallLabel")
        : t("breadcrumbSigning.thirdStepLabel"),
      path: `wizard/signing/signData`,
    },
  ]

  const separator = ">"

  let status = "done"

  return (
    <Container>
      {items.map((item, index) => {
        const path = `${location?.pathname}`

        if (path === `/${item?.path}`) status = "current"
        if (path !== `/${item?.path}` && status === "current")
          status = "notDone"

        return (
          <Element key={index}>
            <button
              onClick={() => navigate(`../${item.path}`, { replace: true })}
            >
              <ElementText status={status}>{item.title}</ElementText>
            </button>
            {index !== items.length - 1 && (
              <div style={{ padding: "0px 15px" }}>{separator}</div>
            )}
          </Element>
        )
      })}
    </Container>
  )
}
