import { useContext, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { LoadingArea, PageContents, MainContainer } from "./styles"
import { Context } from "../../context"
import { Header, FooterBar } from "./components"
import { trpc } from "src/api/trpc"
import {
  Company,
  Contract,
  HiringCard,
  ResignationCard,
} from "server/src/types"
import StartSigning from "./screens/StartSigning"
import { ConfirmData, SignData, Signed, VisualizeContract } from "./screens"
import Download from "./screens/Download"

export type ConfirmationDataType = {
  name: string
  documentNumber: string
  email: string
}

const Signing = () => {
  const [confirmationPdfInfo, setConfirmationPdfInfo] =
    useState<ConfirmationDataType>()
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [nextHandleClick, setNextHandleClick] = useState(() => () => {})

  const navigate = useNavigate()
  const { route } = useParams()
  const { cardId, companyId, category, type, name, token } = useContext(Context)

  const { data, isLoading } = trpc.wizard.getSigningData.useQuery<{
    card: any
    company: Company
    contract: Contract
  }>(
    {
      cardId,
      companyId,
      category,
    },
    {
      enabled: !!cardId && !!companyId,
      onSuccess: (data) => {
        const { contract, card } = data

        const signInfo = contract.signature.find(
          (c) => c.type === type && c.name === name
        )

        if (!signInfo) navigate("/wizard/invalid?type=invalid")

        const emailLink = card.emailLink.find(
          (emailLink) => emailLink.token && token
        )
        if (emailLink?.expired) {
          navigate("/wizard/invalid?type=invalid")
          return
        }

        const isAllSigned = contract.status === "Signed"
        if (isAllSigned) {
          if (route === "download") {
            navigate("/wizard/signing/download")
            return
          }
          navigate("/wizard/signing/signed")
          return
        }

        if (signInfo?.status === "Signed") navigate("/wizard/signing/pending")
      },
      onError: (err) => {
        console.error(err)
        navigate("/wizard/invalid?type=invalid")
      },
    }
  )

  const handleSetConfirmationData = ({
    documentNumber,
    email,
    name,
  }: ConfirmationDataType) => {
    setConfirmationPdfInfo({
      documentNumber,
      email,
      name,
    })
  }

  useEffect(() => {
    if (route && ["finished"].includes(route)) return

    if (data?.card) {
      if (data?.contract.category === "resignation") return

      const limitDate = data?.card?.limitDates.find((ld) =>
        ld.step.includes("sign-contract")
      )

      if (!limitDate || (limitDate && new Date() > new Date(limitDate.date))) {
        navigate(`../wizard/invalid`, { replace: true })
        return
      }
    }
  }, [route, data, navigate])

  const step = () => {
    if (!data) return null

    switch (route) {
      case "start":
        return (
          <StartSigning
            card={data.card as HiringCard | ResignationCard}
            contract={data.contract}
          />
        )
      case "visualizeContract":
        return (
          <VisualizeContract
            contract={data.contract}
            setCurrentPage={setCurrentPage}
            setTotalPages={setTotalPages}
            setNextHandleClick={setNextHandleClick}
          />
        )
      case "confirmData":
        return (
          <ConfirmData
            confirmationData={confirmationPdfInfo}
            setConfirmationData={handleSetConfirmationData}
          />
        )
      case "signData":
        return (
          <SignData
            card={data.card as HiringCard | ResignationCard}
            confirmationData={confirmationPdfInfo}
          />
        )
      case "pending":
      case "signed":
        return <Signed />
      case "download":
        return <Download contract={data.contract} />
      case "expired":
        return <Navigate replace to={"/wizard/invalid?type=expired"} />
      case "finished":
        return <Navigate replace to={"/wizard/finished?type=signing"} />
      default:
        return <Navigate replace to={"/wizard/signing/start"} />
    }
  }

  const hasHeader = ![
    "start",
    "expired",
    "invalid",
    "finished",
    "pending",
    "download",
    "signed",
  ].includes(route || "")

  const hasFooter = ![
    "start",
    "confirmData",
    "expired",
    "invalid",
    "finished",
    "pending",
    "signed",
    "download",
    "signData",
  ].includes(route || "")

  if (isLoading && !data) {
    return (
      <MainContainer>
        <LoadingArea>
          <Skeleton width={647} height={500} variant="rectangular" />
        </LoadingArea>
      </MainContainer>
    )
  }

  return (
    <MainContainer>
      {hasHeader && data?.company && <Header company={data.company} />}

      <PageContents style={{ justifyContent: "center" }}>{step()}</PageContents>

      {hasFooter && (
        <FooterBar
          currentPage={currentPage}
          totalPages={totalPages}
          nextHandleClick={nextHandleClick}
          isLoading={isLoading}
        />
      )}
    </MainContainer>
  )
}

export default Signing
