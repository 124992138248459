import styled, { css } from "styled-components"

const Container = styled.div`
  display: flex;
`

const Element = styled.div`
  display: flex;
  align-items: center;
`

const ElementText = styled.p<{ status: string }>`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ status }) =>
    status === "done" &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}

  ${({ status }) =>
    status === "current" &&
    css`
      color: ${({ theme }) => theme.colors.primary};
    `}
    ${({ status }) =>
    status === "notDone" &&
    css`
      color: #ccc;
    `}
`

export { Container, Element, ElementText }
