import { useContext, useMemo, useState } from "react"
import { cloneDeep } from "lodash-es"

import { Context } from "../../../../../context"
import ArrowRight from "../../../../../assets/arrow_right.svg"

import { uploadHelper } from "@flash-hros/utility"

import { IconButton, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import {
  PreviewDocContainer,
  ButtonContainer,
  ConfirmButton,
  PreviewImageContainer,
  Container,
  CloseContainer,
  SectionTitle,
  FlexColumn,
  PreviewTextContainer,
  IconContainer,
  StyledModal,
  ChildrenContainer,
} from "./styles"
import dispatchToast from "../../../../../utils/dispatchToast"
import { FieldValue, HiringCard } from "server/src/types"
import { trpc } from "src/api/trpc"
import { RenderField, DeprecatedUpload } from "@Components"

interface EditModalProps {
  isOpen: boolean
  handleClose: () => void
  card: HiringCard
  fields: FieldValue[]
}

const EditCandidateModal = ({
  isOpen,
  handleClose,
  card,
  fields,
}: EditModalProps) => {
  const [localFields, setLocalFields] = useState<FieldValue[]>(fields)

  const utils = trpc.useContext()
  const { token } = useContext(Context)

  const { mutateAsync: processingFile } =
    trpc.helper.processingFile.useMutation()
  const { mutateAsync: updateCandidate } =
    trpc.candidate.updateCandidate.useMutation()

  const renderFields = () => {
    const filterFileFields =
      localFields.filter((f) => f.fieldType === "file") || []
    const filterOtherFields =
      localFields.filter((f) => f.fieldType !== "file") || []

    return (
      <FlexColumn width={"70%"}>
        <SectionTitle>{"Dados básicos"}</SectionTitle>
        {filterFileFields.length > 0 &&
          filterFileFields.map((f, index) => {
            const handleFile = async ({
              name,
              file,
            }: {
              name: any
              file: any
            }): Promise<void> => {
              const { companyId, candidateId } = card

              const url = `${
                companyId + "/" + candidateId + "/" + f.fieldId + "/" + name
              }`
              if (!companyId || !candidateId || !f.fieldId || !name) return

              const { key, path } = await uploadHelper({
                key: url,
                file,
                fileName: name,
                module: "employee-pii",
                externalToken: token,
              })

              const processedFile = await processingFile({ path: path })

              const value = {
                key,
                path,
                type: "internal",
                value: processedFile,
              }

              setLocalFields((prev) => {
                const fields = cloneDeep(prev)
                const fieldIndex = fields.findIndex(
                  (field) => f.fieldId === field.fieldId
                )
                if (fieldIndex === -1) return prev

                fields[fieldIndex].value = value
                return fields
              })
            }

            const { value } = f.value || { value: null }

            return value ? (
              <ChildrenContainer key={index}>
                <PreviewDocContainer
                  onClick={(e) => {
                    e.stopPropagation()
                    window.open(value, "_blank")
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <PreviewImageContainer url={value} />
                    <PreviewTextContainer style={{ marginLeft: "10px" }}>
                      <Typography variant="body3">{f.placeholder}</Typography>
                    </PreviewTextContainer>
                  </div>
                  <IconContainer
                    onClick={async (e: any) => {
                      e.stopPropagation()
                      e.preventDefault()

                      setLocalFields((prev) => {
                        const fields = cloneDeep(prev)
                        const fieldIndex = fields.findIndex(
                          (field) => field.fieldId === f.fieldId
                        )
                        if (fieldIndex === -1) return prev

                        fields[fieldIndex].value = ""
                        return fields
                      })
                    }}
                    style={{
                      backgroundColor: "#fff",
                      border: "1px solid #EBE5E9",
                      marginLeft: "10px",
                    }}
                  >
                    <Icons name="IconTrash" fill={"transparent"} />
                  </IconContainer>
                </PreviewDocContainer>
              </ChildrenContainer>
            ) : (
              <ChildrenContainer key={index}>
                <DeprecatedUpload
                  onChange={handleFile}
                  label={f.placeholder}
                  accept={["pdf", "png", "jpg", "jpeg"]}
                  maxSize={5242880}
                  onFileSizeError={() => {
                    dispatchToast({
                      content:
                        "Arquivo maior que 5mb. Por favor, faça upload de arquivo menor que 5mb",
                      type: "warning",
                    })
                  }}
                />
              </ChildrenContainer>
            )
          })}

        {filterOtherFields.length > 0 &&
          filterOtherFields.map((f, index) => {
            return (
              <ChildrenContainer key={index}>
                <div style={{ width: "100%" }}>
                  <RenderField
                    field={f}
                    handleChange={async (value) => {
                      setLocalFields((prev) => {
                        const fields = cloneDeep(prev)
                        const fieldIndex = fields.findIndex(
                          (field) => field.fieldId === f.fieldId
                        )
                        if (fieldIndex === -1) return prev

                        fields[fieldIndex].value = value
                        return fields
                      })
                    }}
                  />
                </div>
              </ChildrenContainer>
            )
          })}
      </FlexColumn>
    )
  }

  const handleSubmit = async () => {
    const fieldsChanged = localFields.filter((localField) => {
      const originalField = fields.find(
        (ogField) => ogField.fieldId === localField.fieldId
      )
      if (!originalField) return false

      return originalField.value !== localField.value
    })
    if (fieldsChanged.length === 0) handleClose()

    const updatedFieldsValue = fieldsChanged.reduce(
      (prev: any, curr: FieldValue) => {
        if (curr.fieldId === "email") {
          const phone = localFields.find((field) => field.fieldId === "phone")
          prev["contacts"] = [
            { type: "email", value: curr.value },
            { type: "phone", value: phone?.value || "" },
          ]
        }

        if (curr.fieldId === "phone") {
          const email = localFields.find((field) => field.fieldId === "email")
          prev["contacts"] = [
            { type: "email", value: email?.value || "" },
            { type: "phone", value: curr.value },
          ]
        }
        prev[curr.fieldId] = curr.value
        return prev
      },
      {}
    )

    await updateCandidate({
      candidateId: card.candidateId,
      fields: updatedFieldsValue,
    })

    utils.wizard.invalidate()

    dispatchToast({
      type: "success",
      content: "Campos atualizados com sucesso!",
    })

    handleClose()
  }

  return (
    <StyledModal
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
    >
      <Container>
        <CloseContainer>
          <IconButton
            onClick={() => {
              handleClose()
            }}
            size="medium"
            variant="line"
            icon="IconX"
          />
        </CloseContainer>

        <FlexColumn style={{ maxWidth: "535px", marginBottom: "63px" }}>
          <Typography
            variant="headline6"
            style={{ marginBottom: "4px", fontWeight: 700 }}
          >
            Editar documento
          </Typography>
        </FlexColumn>

        {renderFields()}

        <ButtonContainer style={{ paddingTop: "60px", paddingBottom: "20px" }}>
          <ConfirmButton onClick={() => handleSubmit()}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body3" style={{ color: "#fff" }}>
                Confirmar
              </Typography>
              <ArrowRight style={{ color: "#fff", marginLeft: "7px" }} />
            </div>
          </ConfirmButton>
        </ButtonContainer>
      </Container>
    </StyledModal>
  )
}

export default EditCandidateModal
