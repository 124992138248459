import styled, { css } from "styled-components"

export const HeaderContent = styled.div`
  flex: 1;
  justify-content: space-between;
  background: #ffffff;
  padding: 20px 43px;
`

export const StepContainer = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 1440px;
  flex: 1;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-top: 40px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-top: 60px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin-top: 250px;
  }
`

export const LoadingArea = styled.div`
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const PageContents = styled.div<{ hasHeader?: boolean }>`
  flex: 1;
  overflow: scroll;
  background-color: #f8f6f8;

  ${({ hasHeader }) => !hasHeader && "justify-content: center"};
`

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f8f9;
  overflow: hidden;
`
