import styled, { css } from "styled-components"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

const Container = styled.div`
  background-color: var(--color-neutral-95);
  padding: 20px;
  width: 100%;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ItemContainer = styled.div<{
  justifyContent?: string
  isMobile?: boolean
  isHeartOrStar?: boolean
  disabled?: boolean
}>`
  ${({ isHeartOrStar, disabled, isMobile }) =>
    isHeartOrStar
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${({ theme }) => theme.colors.neutral[90]};
          gap: 32px;
          padding: 16px 32px;
          border-radius: 4px;
          max-width: 384px;
          flex-wrap: wrap;
          pointer-events: ${disabled ? "none" : "auto"};
        `
      : css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: ${isMobile ? "2px" : "10px"};
          pointer-events: ${disabled ? "none" : "auto"};
        `}
`
const ItemHeartStar = styled.div``

const ItemSubHeartStar = styled.div`
  width: 100%;
  cursor: pointer;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Item = styled.div<{
  hasSelected: boolean
  disabled?: boolean
  isMobile?: boolean
}>`
  width: 100%;
  height: 64px;

  ${({ isMobile }) =>
    isMobile
      ? css`
          min-width: 42px;
          max-width: 42px;
        `
      : css`
          min-width: 52px;
          max-width: 52px;
        `};

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid
    ${({ theme, hasSelected }) =>
      hasSelected ? theme.colors.secondary[50] : "none"};
  background-color: ${({ theme, hasSelected }) =>
    hasSelected ? theme.colors.secondary[99] : theme.colors.neutral[90]};
  cursor: pointer;
  flex: 1;

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
    p {
      color: ${({ theme }) => theme.colors.secondary[50]};
    }

    svg {
      stroke: ${({ theme }) => theme.colors.secondary[50]};
      color: ${({ theme }) => theme.colors.secondary[50]};
    }
    background-color: ${({ theme }) => theme.colors.secondary[99]};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
          cursor: auto;
        `
      : ""}
`

const Num = styled(Typography)<{ size?: number; color?: string }>`
  color: ${({ color }) => color};
  font-weight: 700 !important;
  font-size: ${({ size }) => (size ? `${size}px` : undefined)};
`

const LabelsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

const Label = styled(Typography)<{ hasSelected: boolean }>`
  color: ${({ theme, hasSelected }) =>
    hasSelected ? theme.colors.secondary[50] : theme.colors.neutral[30]};
`

export {
  Container,
  ItemContainer,
  Item,
  Num,
  LabelsContainer,
  Label,
  ItemHeartStar,
  ItemSubHeartStar,
}
