import { ReactNode } from "react"
import { DrawerProps as NativeDrawerProps } from "@mui/material"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  StyledDrawer,
  StyledCloseButton,
  TitleWrapper,
  StyledSubtitle,
  ContentWrapper,
} from "./styles"

type BaseDrawerProps = {
  title: string
  subtitle?: string
  open: boolean
  zIndex?: number
  onClose: () => void
  children: ReactNode
}

export type DrawerProps = NativeDrawerProps & BaseDrawerProps

export const Drawer = ({
  title,
  subtitle,
  open,
  zIndex = 1400,
  children,
  onClose,
  ...props
}: DrawerProps) => {
  return (
    <StyledDrawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      zIndex={zIndex}
      {...props}
    >
      <StyledCloseButton
        size="small"
        variant="line"
        icon="IconX"
        onClick={onClose}
      />
      <TitleWrapper>
        <Typography variant="headline7">{title}</Typography>
        {subtitle && (
          <StyledSubtitle variant="body3">{subtitle}</StyledSubtitle>
        )}
      </TitleWrapper>

      <ContentWrapper>{children}</ContentWrapper>
    </StyledDrawer>
  )
}
