import { useState } from "react"
import { trpc } from "./api/trpc"
import { httpBatchLink } from "@trpc/client"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { getFromLS, removeFromLS } from "@flash-hros/utility"

import App from "./App"
import dispatchToast from "@Utils/dispatchToast"

export default function Root() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            retry: false,
            onError(err: any) {
              if (err?.status === 403) {
                removeFromLS({ key: "wizard-token" })
                window.location.pathname = "/wizard/invalid?type=expired"
                return
              }

              dispatchToast({
                type: "error",
                content: err.message,
              })
            },
          },
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
            onError(err: any) {
              if (err?.status === 403) {
                removeFromLS({ key: "wizard-token" })
                window.location.pathname = "/wizard/invalid?type=expired"
                return
              }

              dispatchToast({
                type: "error",
                content: err.message,
              })
            },
          },
        },
      })
  )

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${process.env.BFF_URL}/trpc`,
          headers: async () => {
            const token = await getFromLS("wizard-token")
            return {
              Authorization: token,
            }
          },
        }),
      ],
    })
  )

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <App />
        {process.env.NODE_ENV !== "production" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </QueryClientProvider>
    </trpc.Provider>
  )
}
