import { useEffect, useState } from "react"

import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2"

const DeprecatedUpload = ({
  label,
  onChange,
  accept,
  customPreview,
  maxSize,
  onFileSizeError,
}: {
  label: any
  onChange: any
  accept?: any
  customPreview?: any
  maxSize?: number
  onFileSizeError?: any
}) => {
  const [file, setFile] = useState<{ [key: string]: any }>({})

  useEffect(() => {
    if (Object.keys(file).length) {
      onChange({ name: file?.name, file: file?.file })
      setFile({})
    }
  }, [file])

  const handleDropFile = async (acceptedFiles: any) => {
    const file = acceptedFiles?.[0]?.file || ""
    const name = acceptedFiles?.[0]?.id || ""

    if (file) setFile({ name, file: file })
  }

  return (
    <Dropzone
      accept={accept}
      title={label}
      maxSize={maxSize}
      onChange={handleDropFile}
      onFileSizeError={onFileSizeError}
      customPreview={customPreview}
      style={{ width: "100%%" }}
    />
  )
}

export { DeprecatedUpload }
