import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 492px;
  max-width: 100%;
  height: 100vh;
  background-color: #ffffff;
  padding-bottom: 40px;
  margin-right: ${({ visible }) => (visible ? 0 : "-493px")};
  transition: 0.4s;
  word-break: break-all;
  overflow-y: auto;
  z-index: 4;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 50px 50px 30px 50px;
`

const FormContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px 30px 50px;
`

const DateContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 5px;
`

const ScaleContainer = styled.div`
  width: 100%;
  margin: 30px 0;
`

const FieldContainer = styled.div`
  width: 100%;
  * {
    width: 100%;
  }
  margin-bottom: 30px;
`

const ButtonContainer = styled(FieldContainer)`
  margin-bottom: 0;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :hover {
    color: var(--color-neutral-light1);
  }
`

const BackButtonText = styled(Typography)`
  color: var(--color-neutral-dark1);
  margin-left: 2px;
`

const Title = styled(Typography)`
  color: var(--color-neutral-dark3);
  margin-top: 10px;
`

const Date = styled(Typography)`
  color: var(--color-neutral-dark5);
`

const DisabledTag = styled(Tag)`
  color: var(--color-neutral-dark1) !important;
`

const InfoText = styled(Typography)`
  color: var(--color-neutral-dark5);
  margin-bottom: 10px;
  max-width: 355px;
`

const EmptyStateContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  * {
    text-align: center;
  }
`

export {
  Container,
  Header,
  BackButton,
  BackButtonText,
  FormContainer,
  DateContainer,
  ScaleContainer,
  FieldContainer,
  ButtonContainer,
  Title,
  Date,
  DisabledTag,
  InfoText,
  EmptyStateContainer,
}
