import styled, { css } from "styled-components"

export const Container = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 647px;
  max-height: 548px;
  border-radius: 8px;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: 440px;
    padding: 0px 20px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 360px;
    padding: 0px 20px;
  }
`

export const ProposalTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;
  span {
    text-transform: capitalize;
  }
`

export const Avatar = styled.div`
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: -37px 0px 37px;
  display: flex;
  font-size: 80px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.colors.primary};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
`

export const ProposalSubtitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #6d5a66;
  margin-bottom: 25px;
`

export const ExpireDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 18px;
  color: #6d5a66;
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #6d5a66;
  margin-bottom: 47px;
  max-width: 440px;
`

export const LogoArea = styled.div`
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a2909c;
  margin-top: 35px;
`

export const VisualizeButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border-radius: 12px;
  width: 290px;
  cursor: pointer;
  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }
  @media screen and (max-width: 1336px) {
    width: 200px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
