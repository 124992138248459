import { useEffect, useState } from "react"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import {
  Item,
  LabelsContainer,
  Label,
  ItemContainer,
  Container,
  Num,
  ItemHeartStar,
  ItemSubHeartStar,
} from "./styles"

const getResearchIcon = ({
  index,
  scale,
  size,
  color,
  fill,
}: {
  index: number
  scale: "emoji" | "scale" | "star" | "heart" | "enps"
  size?: number
  color?: string
  fill?: string
}) => {
  if (scale === "scale" || scale === "enps")
    return (
      <Num variant="body1" size={size} color={color}>
        {index}
      </Num>
    )
  if (scale === "emoji") {
    if (index === 1)
      return <Icons name="IconMoodSad2" color={color} fill={fill} size={size} />
    if (index === 2)
      return <Icons name="IconMoodSad" color={color} fill={fill} size={size} />
    if (index === 3)
      return (
        <Icons name="IconMoodEmpty" color={color} fill={fill} size={size} />
      )
    if (index === 4)
      return (
        <Icons name="IconMoodSmileBeam" color={color} fill={fill} size={size} />
      )
    if (index === 5)
      return (
        <Icons name="IconMoodHappy" color={color} fill={fill} size={size} />
      )
  }
  return "-"
}

const Scale = ({
  scale,
  type,
  lowerLabel,
  upperLabel,
  onChange,
  initialValue,
  disabled,
  scaleRange,
  isMobile,
}: {
  scale: "emoji" | "scale" | "star" | "heart" | "enps"
  type: "research" | "enps"
  lowerLabel: string
  upperLabel: string
  onChange?: any
  initialValue?: any
  disabled?: boolean
  scaleRange?: any
  isMobile?: boolean
}) => {
  const [value, setValue] = useState<any>("")
  const [hoverHeartStar, setHoverHeartStar] = useState<any>(null)

  useEffect(() => {
    if (onChange) onChange(value)
  }, [value])

  useEffect(() => {
    if (initialValue) setValue(initialValue)
  }, [initialValue])

  const array = [] as any

  const min = scaleRange?.min || 0
  const max = scaleRange?.max || 10

  for (let i = min; i <= max; i++) {
    array.push(i)
  }

  return (
    <Container>
      <div>
        <ItemContainer
          isMobile={isMobile}
          isHeartOrStar={!!["heart", "star"].includes(scale)}
          disabled={disabled}
        >
          {array?.map((i) => {
            const hasSelectedNumberEmoji = value === i
            const hasSelected = value ? value >= i : false
            const hasDisabled = disabled
            const isHovering =
              hoverHeartStar !== null ? hoverHeartStar >= i : false

            return (
              <>
                {["heart", "star"].includes(scale) ? (
                  <ItemSubHeartStar
                    key={i}
                    onMouseOver={() => setHoverHeartStar(i)}
                    onMouseLeave={() => setHoverHeartStar(null)}
                    onClick={() => {
                      if (i === value) {
                        setValue("")
                        setHoverHeartStar(null)
                        return
                      }

                      setValue(i)
                      setHoverHeartStar(null)
                    }}
                  >
                    <Icons
                      name={scale === "heart" ? "IconHeart" : "IconStar"}
                      color={
                        hasSelected || isHovering
                          ? "var(--color-secondary-50)"
                          : hasDisabled
                          ? "var(--color-neutral-70)"
                          : "var(--color-neutral-10)"
                      }
                      fill={
                        isHovering
                          ? "var(--color-secondary-50)"
                          : "var(--color-neutral-90)"
                      }
                    />
                  </ItemSubHeartStar>
                ) : (
                  <Item
                    isMobile={isMobile}
                    hasSelected={hasSelectedNumberEmoji}
                    disabled={disabled}
                    onClick={() =>
                      hasSelectedNumberEmoji ? setValue("") : setValue(i)
                    }
                    key={i}
                  >
                    {getResearchIcon({
                      index: i,
                      scale,
                      color: hasSelectedNumberEmoji
                        ? "var(--color-secondary-50)"
                        : "var(--color-neutral-10)",
                      fill: hasSelectedNumberEmoji
                        ? "var(--color-secondary-99)"
                        : "var(--color-neutral-90)",
                    })}
                  </Item>
                )}
              </>
            )
          })}
        </ItemContainer>
        {lowerLabel || upperLabel ? (
          <LabelsContainer>
            <Label hasSelected={value === min} variant="body4">
              {lowerLabel}
            </Label>
            <Label hasSelected={value === max} variant="body4">
              {upperLabel}
            </Label>
          </LabelsContainer>
        ) : null}
      </div>
    </Container>
  )
}

export { Scale, getResearchIcon }
