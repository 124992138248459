import { useRef, useState, useEffect, useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Stepper } from "../../index"

import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  Header,
  LogoContainer,
  AvatarCircle,
  CompanyName,
  MenuCircle,
  ProgressBarContainer,
  ProgressBar,
  MenuContainer,
} from "./styles"
import { Candidate, Card, Company, HiringFlowList } from "server/src/types"

const Mobile = ({
  candidate,
  company,
  card,
  flowList,
}: {
  candidate: Candidate
  company: Company
  card: Card
  flowList: HiringFlowList
}) => {
  const { route } = useParams()

  const ref = useRef<HTMLDivElement>(null)

  const [menuContainerHeight, setMenuContainerHeight] = useState(0)
  const [active, setActive] = useState(false)

  const progress = useMemo(() => {
    switch (route) {
      case "basic-data":
        return "25%"
      case "candidate":
        return "50%"
      case "dependents":
        return "75%"
      case "confirmation":
        return "100%"
      default:
        return "0%"
    }
  }, [route])

  useEffect(() => {
    if (ref.current) setMenuContainerHeight(ref.current.clientHeight)
  }, [])

  const setMenuActive = useCallback(() => {
    setActive(!active)
  }, [active])

  return (
    <Container ref={ref}>
      <ProgressBarContainer>
        <ProgressBar
          initial={{ opacity: 1, width: 0 }}
          animate={{ opacity: 1, width: progress }}
          transition={{ duration: 0.7 }}
        />
      </ProgressBarContainer>
      <Header>
        <LogoContainer>
          <AvatarCircle
            hasLogo={!!company?.logo?.path}
            logoPath={company?.logo?.path || ""}
          />
          <CompanyName>{company?.name || company?.legalName || ""}</CompanyName>
        </LogoContainer>
        <MenuCircle onClick={setMenuActive}>
          <Icons
            name={active ? "IconX" : "IconMenu"}
            fill={"#6D5A66"}
            size={18}
            onClick={() => {}}
          />
        </MenuCircle>
      </Header>
      <MenuContainer menuHeight={menuContainerHeight} active={active}>
        <Stepper onItemClick={setMenuActive} />
      </MenuContainer>
    </Container>
  )
}

export default Mobile
