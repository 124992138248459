import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  ContentWrapper,
  IconContainer,
  MainContainer,
  ModalContainer,
} from "./styles"
import { Navbar } from "@Components"

interface StartProps {
  status: string
  handleNextStep: () => void
}

export const Start = ({ status, handleNextStep }: StartProps) => {
  return (
    <MainContainer>
      <Navbar />

      <Container>
        <ModalContainer>
          <IconContainer>
            <Icons name="IconReportMedical" fill="transparent" />
          </IconContainer>

          <ContentWrapper>
            <Typography variant="headline7" variantColor="#F20D7A">
              {status === "resent" ? "Reenvie" : "Envie"} seu atestado de saúde
              ocupacional
            </Typography>
            <Typography variant="body3" variantColor="#83727D">
              Clique para prosseguir e enviar o documento.
            </Typography>
            <div>
              <Button variant="primary" size="large" onClick={handleNextStep}>
                Enviar exame
              </Button>
            </div>
          </ContentWrapper>
        </ModalContainer>
      </Container>
    </MainContainer>
  )
}
