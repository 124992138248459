import { useContext, useMemo } from "react"
import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  ExpireDate,
  Description,
  Avatar,
  LogoArea,
  TextArea,
  FlexCenter,
} from "./styles"

import Logo from "../../../../../assets/logo_flash.svg"
import { Context } from "src/context"
import { Candidate } from "server/src/types"
import { createSegmentTrack, trackList } from "@Utils/segment"

const Mobile = ({ candidate }: { candidate: Candidate }) => {
  const navigate = useNavigate()
  const { expireDate } = useParams()
  const { subcategory } = useContext(Context)

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  return (
    <FlexCenter>
      <Container>
        <Avatar>{candidate.name?.substring(0, 1)}</Avatar>
        <ProposalTitle variant="headline6">
          {t("proposal.start.proposalTitle")},{" "}
          <span>{candidate.name?.split(" ")[0]}</span>!
        </ProposalTitle>
        <ProposalSubtitle variant="body3">
          {t("proposal.start.proposalSubtitle")}
        </ProposalSubtitle>
        {expireDate ? (
          <ExpireDate variant="body4">
            Responda até 25/08/2022 às 22h
          </ExpireDate>
        ) : null}
        <Description variant="body3">
          {t("proposal.start.description")}
        </Description>

        <FlexCenter>
          <Button
            size="medium"
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              const track = trackList?.[subcategory]?.employee?.proposalView
              if (track)
                createSegmentTrack({
                  track: track,
                })

              navigate(`../wizard/proposal/confirmation`, {
                replace: true,
              })
            }}
          >
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              {t("proposal.start.buttonLabel")}
            </Typography>
          </Button>
        </FlexCenter>
      </Container>
      <LogoArea>
        <TextArea variant={"caption"}>
          {t("proposal.start.developedBy")}
        </TextArea>
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </FlexCenter>
  )
}

export default Mobile
