import { Dropzone, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"
import { useContext, useState } from "react"
import { UploadPreview } from "../UploadPreview"
import dispatchToast from "../../../utils/dispatchToast"
import { uploadHelper } from "@flash-hros/utility"
import { S3File } from "server/src/types"
import { trpc } from "src/api/trpc"
import { Context } from "src/context"

type Modules = "hiring" | "employee-pii"

interface UploadProps {
  value?: S3File
  label: string
  folder: string
  onUpload?: (file: S3File) => Promise<void>
  onRemove?: (file: S3File) => Promise<void>
  canDelete?: boolean
  hasShareLink?: boolean
  accept: string[]
  maxSize?: number
  module?: Modules
  customFilename?: string
}

export const uploadFile = async (
  folder: string,
  module: Modules,
  fileName: string,
  file: any,
  token: string
): Promise<S3File> => {
  const { key, path } = await uploadHelper({
    key: `${folder}/${fileName}`,
    file,
    fileName,
    module: module,
    externalToken: token,
  })
  return { key, path, type: "internal" }
}

export const Upload = ({
  label,
  value,
  accept,
  onUpload,
  folder,
  hasShareLink = false,
  canDelete = true,
  maxSize = 5242880,
  onRemove,
  module = "hiring",
  customFilename,
}: UploadProps) => {
  const [localFile, setLocalFile] = useState<S3File | undefined>(value)

  const { token } = useContext(Context)
  const { mutateAsync: mutateGetS3File } = trpc.helper.getS3File.useMutation()

  const handleRemove = (file: S3File) => {
    setLocalFile(undefined)
    if (onRemove) onRemove(file)
  }

  const handleChange = async (files) => {
    try {
      const { file, id: fileName } = files[0]
      const name = customFilename
        ? `${Date.now().toString()}-${customFilename}`
        : fileName
      const uploadedFile = await uploadFile(folder, module, name, file, token)
      uploadedFile.value = await mutateGetS3File({
        file: uploadedFile,
        module: module,
      })

      setLocalFile(uploadedFile)
      if (onUpload) await onUpload(uploadedFile)
    } catch (err) {
      dispatchToast({
        content: "Algo aconteceu ao fazer o upload do arquivo.",
        type: "error",
      })
    }
  }

  if (localFile)
    return (
      <UploadPreview
        file={localFile}
        hasShareLink={hasShareLink}
        handleRemove={canDelete ? handleRemove : undefined}
      />
    )

  return (
    <Dropzone
      accept={accept}
      title={label}
      multiple={false}
      onChange={handleChange}
      style={{ width: "100%" }}
      maxSize={maxSize}
      customPreview={() => <Skeleton variant="rectangular" height={114} />}
      onFileSizeError={() => {
        dispatchToast({
          content:
            "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
          type: "error",
        })
      }}
    />
  )
}
