import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 82px 112px 48px 112px;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 80px;
    flex-direction: column;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 60px 40px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    padding: 30px 15px;
  }
`

const LeftContainer = styled.div`
  margin: 54px 92px 0 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-right: 40px;
  }
`

const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

const StepTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 213px;
  margin: 0;
  padding: 0;
  margin-bottom: 22px;
`

const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #856f7e;
  max-width: 348px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

const AccordionChildren = styled.div`
  border: 1px solid #e8e3e6;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 44px 40px 44px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const AccordionChildrenItems = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column-reverse;
  }
`

const AccordionElement = styled.div<{ hasId: boolean }>`
  border-bottom: 1px solid #ebe5e9;
  opacity: ${({ hasId }) => (hasId ? 1 : 0.5)};
  pointer-events: ${({ hasId }) => (hasId ? "auto" : "none")};
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #856f7e;
`

const SectionSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #856f7e;
  margin-bottom: 24px;
`

const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
`

const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

const StyledPOneLine = styled.p`
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PreviewDocWithoutContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  pointer-events: none;
  width: 100%;

  max-width: 300px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 100%;
  }
`

const PreviewDocContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;
  cursor: pointer;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  width: 100%;

  max-width: 300px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 100%;
  }
`

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`

export {
  Container,
  LeftContainer,
  RightContainer,
  StepTitle,
  SubTitle,
  PreviewDocContainer,
  AccordionChildren,
  AccordionChildrenItems,
  ButtonContainer,
  AccordionElement,
  FlexColumn,
  SectionTitle,
  SectionSubTitle,
  PreviewImageContainer,
  PreviewTextContainer,
  StyledPOneLine,
  PreviewDocWithoutContainer,
  ChildrenContainer,
}
