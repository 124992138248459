import styled from "styled-components"

const CollapseContainer = styled.div<{ expanded: boolean }>`
  width: 100%;
  display: flex;
  overflow: elipsis;

  padding: 40px 44px;

  cursor: pointer;
  border: 1px solid #ebe5e9;
  border-radius: 8px;
  margin-top: 40px;

  word-break: break-word;

  :first-child {
    margin-top: 0px;
  }

  ${({ expanded }) =>
    expanded
      ? "border-bottom: 0px; border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
      : ""}
`

const FlexSpaceBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    text-align: center;
  }
`

const OptionalSubContainer = styled.div`
  max-width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 4px 12px;
  background: ${({ theme }) => theme?.colors?.primary};
  border-radius: 24px;

  margin-left: 0px;
  margin-bottom: 4px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }
`

const IconContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #f6f3f6;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
  }
`

const EditIconContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #fff;
  border: 1px solid #ebe5e9;
  margin-right: 16px;

  svg {
    &:hover {
      opacity: 0.5;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
    margin-right: 0px;
  }
`

const StyledPOneLine = styled.p`
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const OptionalContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
  }
`

const IconTrashContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #fff;

  border: 1px solid ${({ theme }) => theme.colors.feedback.error[70]};

  &:hover {
    opacity: 0.5;
  }

  svg {
    stroke: ${({ theme }) => theme.colors.feedback.error[70]};
  }

  margin-right: 10px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
  }
`

export {
  CollapseContainer,
  FlexSpaceBetween,
  IconContainer,
  EditIconContainer,
  OptionalContainer,
  OptionalSubContainer,
  StyledPOneLine,
  IconTrashContainer,
}
