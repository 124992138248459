import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { Container, Step, StepArea } from "./styles"
import { Context } from "../../../context"

type LabelData = {
  basicData: string
  candidate: string
  dependents: string
  confirmation: string
}

type LabelList = { [hiringFlowId: string]: LabelData }

export const Stepper = ({ onItemClick }: { onItemClick: () => any }) => {
  const { subcategory } = useContext(Context)
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const navigate = useNavigate()
  const location = useLocation()

  const labelList: LabelList = {
    ["pj"]: {
      basicData: t("breadcrumb.pj.firstStepLabel"),
      candidate: t("breadcrumb.pj.secondStepLabel"),
      dependents: t("breadcrumb.pj.thirdStepLabel"),
      confirmation: t("breadcrumb.pj.fourthStepLabel"),
    },
    ["clt"]: {
      basicData: t("breadcrumb.clt.firstStepLabel"),
      candidate: t("breadcrumb.clt.secondStepLabel"),
      dependents: t("breadcrumb.clt.thirdStepLabel"),
      confirmation: t("breadcrumb.clt.fourthStepLabel"),
    },
    ["internship"]: {
      basicData: t("breadcrumb.internship.firstStepLabel"),
      candidate: t("breadcrumb.internship.secondStepLabel"),
      dependents: t("breadcrumb.internship.thirdStepLabel"),
      confirmation: t("breadcrumb.internship.fourthStepLabel"),
    },
  }

  const hiringType = subcategory || "clt"
  const label = labelList[hiringType]

  const items = [
    {
      title: label.basicData,
      path: `wizard/hiring/basic-data`,
    },
    {
      title: label.candidate,
      path: `wizard/hiring/candidate`,
    },
    {
      title: label.dependents,
      path: `wizard/hiring/dependents`,
    },
    {
      title: label.confirmation,
      path: `wizard/hiring/confirmation`,
    },
  ]

  let status = "done"

  return (
    <Container>
      <StepArea>
        {items?.map(({ title, path }, index) => {
          if (location?.pathname === `/${path}`) status = "current"
          if (location?.pathname !== `/${path}` && status === "current")
            status = "notDone"
          return (
            <Step status={status} key={index}>
              {title}
            </Step>
          )
        })}
      </StepArea>
    </Container>
  )
}
