import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 647px;

  border-radius: 8px;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
`

export const ProposalTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;

  text-align: center;
  font-weight: 700;
`

export const Avatar = styled.div<{ logoPath?: string }>`
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: -37px 0px 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${({ logoPath }) => logoPath});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const ProposalSubtitle = styled(Typography)`
  text-align: center;
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-bottom: 10px;
`

export const ExpireDate = styled(Typography)`
  font-weight: 600;
  margin-bottom: 18px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`

export const Description = styled(Typography)`
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 47px;
`

export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a2909c;
  margin-top: 35px;
`

export const TextArea = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[60]};
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
