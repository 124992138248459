import styled from "styled-components"

export const StatusContainer = styled.div`
  padding: 4px 8px;
  display: inline-flex;
  align-self: flex-start;
  align-items: center;

  background-color: #f6f8f9;
  border-radius: 24px;
  border-width: 1px;
  borde-style: solid;
`
