import { Component, FieldType } from "server/src/types"
import { MandatoryField } from "src/pages/MainPage/screens/DependentsStep/RenderDependents"

export const mandatoryFieldsMock: MandatoryField[] = [
  {
    fieldName: "kinship",
    fieldType: FieldType.Text,
    placeholder: "Grau de parentesco",
    component: Component.Select,
    fieldRule: {
      fieldId: "kinship",
      required: true,
    },
    options: [
      "conjuge",
      "enteados",
      "filho(a)",
      "filho(a) adotivo(a)",
      "união estável",
      "irmão(ã), neto(a), bisneto(a)",
      "pai, avô(ó) e bisavô(ó)",
    ],
  },
  {
    fieldName: "name",
    fieldType: FieldType.Text,
    placeholder: "Nome do dependente",
    component: Component.ShortText,
    fieldRule: {
      fieldId: "name",
      required: true,
    },
  },
  {
    fieldName: "birthDate",
    fieldType: FieldType.Text,
    placeholder: "Data de nascimento",
    component: Component.DatePicker,
    fieldRule: {
      fieldId: "birthDate",
      required: true,
    },
  },
]
