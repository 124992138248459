import React, { useState } from "react"
import { removeDropzoneTimestamp } from "@Utils/index"
import { Skeleton } from "@mui/material"
import { IconButton, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { PreviewContainer, PreviewImage, StyledLinkButton } from "./styles"
import dispatchToast from "../../../utils/dispatchToast"
import { S3File } from "server/src/types"

interface UploadPreviewProps {
  file: S3File
  handleRemove?: (file: S3File) => void
  hasShareLink?: boolean
  customName?: string
}

export const UploadPreview = ({
  file,
  handleRemove,
  hasShareLink = false,
  customName,
}: UploadPreviewProps) => {
  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const nameOfArchive = React.useMemo(() => {
    if (customName) return customName

    const lastIndexName = file.key?.lastIndexOf("/") || ""
    if (file.key && lastIndexName) {
      const keyFileName = file.key.substring(lastIndexName + 1)
      return removeDropzoneTimestamp(keyFileName)
    }

    return "Nome não identificado"
  }, [file, customName])

  if (isRemoving)
    return <Skeleton variant="rectangular" width="100%" height="114px" />

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <PreviewContainer>
        <PreviewImage url={file.value} />
        <Typography
          variant="body4"
          variantColor="#53464F"
          style={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {nameOfArchive}
        </Typography>

        <div style={{ display: "flex" }}>
          {handleRemove && (
            <IconButton
              size="medium"
              variant="line"
              icon="IconTrash"
              onClick={async (e) => {
                e.stopPropagation()
                e.preventDefault()

                setIsRemoving(true)
                await handleRemove(file)
                setIsRemoving(false)
              }}
              style={{ marginRight: "10px" }}
            />
          )}

          <IconButton
            size="medium"
            variant="line"
            icon="IconDownload"
            onClick={async () => {
              if (!file.value) return
              const readFile = await fetch(file.value)
              const fileToArrayBuffer = await readFile.arrayBuffer()
              const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
              const a = document.createElement("a")
              a.href = res
              a.download = nameOfArchive
              a.click()
            }}
          />
        </div>
      </PreviewContainer>

      {hasShareLink && (
        <div style={{ marginTop: "4px" }}>
          <StyledLinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={async () => {
              if (!file.value) return
              navigator.clipboard.writeText(file.value)
              dispatchToast({
                content: "Link copiado",
                type: "success",
              })
            }}
          >
            <Typography
              variant="caption"
              variantColor="#6B5B66"
              style={{ fontWeight: 700 }}
            >
              Compartilhar link
            </Typography>
            <Icons
              name="IconLink"
              size={16}
              style={{
                fill: "transparent",
                color: "#6B5B66",
              }}
            />
          </StyledLinkButton>
        </div>
      )}
    </div>
  )
}
