import styled from "styled-components"

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f8f9;
  overflow: hidden;
`

export const PageContents = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 37px 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
`
