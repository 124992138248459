import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 82px 112px 48px 112px;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 80px;
    flex-direction: column;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 60px 40px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    padding: 30px 15px;
  }
`

const LeftContainer = styled.div`
  margin: 54px 92px 0 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-right: 40px;
  }
`

const RightContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 40px;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

const StepTitle = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 167px;
  margin-bottom: 22px;
`

const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  max-width: 348px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`

const AccordionChildren = styled.div`
  border: 1px solid #e8e3e6;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 44px 40px 44px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column-reverse;
  }
`

export {
  Container,
  LeftContainer,
  RightContainer,
  StepTitle,
  SubTitle,
  AccordionChildren,
  ButtonContainer,
}
