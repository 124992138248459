import styled, { css } from "styled-components"

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f8f9;
  overflow: hidden;
`

export const PageContents = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 37px 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
`

export const Container = styled.div`
  background: #fff;
  width: 100%;
  display: flex;
  max-width: 647px;
  max-height: 500px;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: 440px;
    padding: 0px 20px;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 360px;
    padding: 0px 20px;
  }
`

export const ProposalTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 24px;

  @media screen and (max-width: 300px) {
    font-size: 26px;
  }
`

export const Avatar = styled.div<{ logoPath?: string }>`
  width: 100px;
  height: 100px;
  min-height: 100px;
  margin: -37px 0px 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${({ logoPath }) => logoPath});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

export const ProposalSubtitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #6d5a66;
  margin-bottom: 25px;
`

export const ExpireDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 18px;
  color: #6d5a66;
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #6d5a66;
  margin-bottom: 47px;
`

export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a2909c;
  margin-top: 35px;
`
