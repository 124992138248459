import { useContext } from "react"
import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ProposalSubtitle,
  ExpireDate,
  Description,
  Avatar,
  LogoArea,
  VisualizeButton,
} from "./styles"

import Logo from "../../../../assets/logo_flash.svg"
import { Context } from "../../../../context"
import { createSegmentTrack, trackList } from "@Utils/segment"
import { Candidate } from "server/src/types"

const StartStep = ({ candidate }: { candidate: Candidate }) => {
  const { subcategory } = useContext(Context)
  const navigate = useNavigate()
  const { expireDate } = useParams()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  return (
    <>
      <Container>
        <Avatar>{candidate.name.substring(0, 1).toUpperCase()}</Avatar>
        <ProposalTitle>
          <span>{candidate.name.split(" ")[0]}</span>, chegou a hora de enviar
          seus documentos!
        </ProposalTitle>
        <ProposalSubtitle>
          Reserve um tempo para enviar seus documentos{" "}
        </ProposalSubtitle>
        {expireDate ? (
          <ExpireDate>Responda até 25/08/2022 às 22h</ExpireDate>
        ) : null}
        <Description>
          Estamos prontos para avançar com sua contratação 😁. <br />O nosso
          processo é totalmente online e seguro. Recomendamos que você reserve
          um tempo para separar e enviar toda documentação necessária antes do
          prazo final, combinado? Em caso de dúvidas fale com o RH.
        </Description>
        <VisualizeButton
          onClick={() => {
            const track = trackList?.[subcategory]?.employee?.sendDocStart
            if (track)
              createSegmentTrack({
                track: track,
              })

            navigate(`../wizard/hiring/basic-data`, {
              replace: true,
            })
          }}
        >
          <Typography variant="body3" style={{ fontWeight: 700 }}>
            Começar
          </Typography>
        </VisualizeButton>
      </Container>

      <LogoArea>
        {t("proposal.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}

export default StartStep
