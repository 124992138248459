import { useMemo } from "react"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { Candidate } from "server/src/types"

import Desktop from "./Desktop"
import Mobile from "./Mobile"

const StartProposal = ({ candidate }: { candidate: Candidate }) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const ComponentRender = useMemo(
    () => (isMobile ? Mobile : Desktop),
    [isMobile]
  )

  return <ComponentRender candidate={candidate} />
}

export default StartProposal
