import styled from "styled-components"

export const MainContainer = styled.div`
  background: #f8f6f8;
`

export const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 40px 56px;
  border: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    gap: 0;
    flex-direction: row;
    width: calc(100% - 20px);
    margin: 40px 10px;
    padding: 40px 56px;
    border-radius: 8px;
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    width: calc(100% - 160px);
    margin: 80px;
    padding: 80px 112px;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  gap: 22px;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 30%;
    margin-right: 56px;
  }

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    width: 45%;
    margin-right: 80px;
  }
`

export const RightContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`
export const AsoContainer = styled.div<{ status: "default" | "declined" }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid
    ${({ theme, status }) =>
      status === "declined"
        ? theme.colors.feedback.error[70]
        : theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px 44px;
  gap: 32px;

  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`
export const PreviewGuide = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px 44px;
  gap: 16px;

  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`
export const ButtonContainer = styled.div`
  padding: 24px 12px;
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.1);
  background: #fff;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    box-shadow: none;
    padding: 0;
  }
`

export const RefusedContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex: 1;
  margin-top: 32px;

  > svg {
    color: ${({ theme }) => theme.colors.feedback.error[40]};
    width: 16px;
    height: 16px;
  }
`

export const StyledPre = styled.pre`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`
