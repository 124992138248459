import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useMediaQuery, Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  HeaderContent,
  CompanyName,
  LogoContainer,
  AvatarCircle,
  CenterContainer,
  RightContainer,
  ProgressBarContainer,
  ProgressBar,
} from "./styles"

import { BreadCrumbsSigning } from "@Components"

import Logo from "../../../../../assets/logo_flash.svg"
import { Company } from "server/src/types"

const Desktop = ({ company }: { company: Company }) => {
  const { route } = useParams()

  const isSmallScreen = useMediaQuery("(max-width: 1010px)")

  const progress = useMemo(() => {
    switch (route) {
      case "visualizeContract":
        return "33.33%"
      case "confirmData":
        return "66.66%"
      case "signData":
        return "100%"
      default:
        return "0%"
    }
  }, [route])

  return (
    <Container>
      <HeaderContent>
        <LogoContainer>
          <AvatarCircle
            hasLogo={!!company?.logo?.path}
            logoPath={company?.logo?.path || ""}
          />
          {!isSmallScreen && (
            <CompanyName>
              {company?.name || company?.legalName || ""}
            </CompanyName>
          )}
        </LogoContainer>
        <CenterContainer>
          <BreadCrumbsSigning />
        </CenterContainer>
        <RightContainer>
          <Logo style={{ marginLeft: "8px" }} />
        </RightContainer>
        <ProgressBarContainer>
          <ProgressBar
            initial={{ opacity: 1, width: 0 }}
            animate={{ opacity: 1, width: progress }}
            transition={{ duration: 0.7 }}
          />
        </ProgressBarContainer>
      </HeaderContent>
    </Container>
  )
}

export default Desktop
