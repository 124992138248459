import {
  Button,
  DatePicker,
  Icons,
  Loader,
  Typography,
  dayjs,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  MainContainer,
  AsoContainer,
  ButtonContainer,
  Container,
  LeftContainer,
  PreviewGuide,
  RightContainer,
  RefusedContainer,
  StyledPre,
} from "./styles"
import { Navbar, StatusCard, Upload, UploadPreview } from "@Components"
import { Employee, ResignationCard } from "server/src/types"
import { trpc } from "src/api/trpc"
import dispatchToast from "@Utils/dispatchToast"
import { useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import i18n from "../../../../i18n"

interface ContentProps {
  employee: Employee
  card: ResignationCard
}

const ButtonSubmit = ({
  handleSubmit,
  isLoading,
}: {
  handleSubmit: () => void
  isLoading: boolean
}) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationAso.content",
  })

  return (
    <ButtonContainer>
      <Button
        variant="primary"
        size="large"
        onClick={handleSubmit}
        style={{ width: "100%" }}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader size="small" variant="primary" />
        ) : (
          <>
            {t("buttonNext")}
            <Icons name="IconArrowRight" fill="transparent" />
          </>
        )}
      </Button>
    </ButtonContainer>
  )
}

const validationSchema = yup.object({
  date: yup.string().required(i18n.t("page.resignationAso.requiredField")),
  file: yup
    .object({
      key: yup.string().required(),
      path: yup.string().required(),
      type: yup.string().required(),
    })
    .required(i18n.t("page.resignationAso.requiredField")),
})

export const Content = ({ card }: ContentProps) => {
  const isMobile = useMediaQuery("(max-width: 576px)")
  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationAso.content",
  })

  const { mutateAsync: submitResignationExam, isLoading: isSubmiting } =
    trpc.wizard.submitResignationExam.useMutation()

  const formik = useFormik({
    initialValues: {
      date: card?.resignationExam?.date,
      file: card?.resignationExam?.file,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (!values.file || !values.date) {
          dispatchToast({
            type: "error",
            content: t("errorValidation"),
          })
          return
        }

        await submitResignationExam({
          cardId: card._id,
          date: new Date(values.date),
          file: values.file,
        })
        navigate("/wizard/finished?type=resignationAso")
      } catch (err) {
        console.error(err)
        dispatchToast({
          type: "error",
          content: t("errorHttp"),
        })
      }
    },
  })

  return (
    <MainContainer>
      <Navbar />

      <Container>
        <LeftContainer>
          <Typography variant="headline7" variantColor="#F20D7A">
            {t("title")}
          </Typography>
          <Typography variant="body3" variantColor="#83727D">
            {t("description")}
          </Typography>
        </LeftContainer>

        <RightContainer>
          {card.resignationExam.medicalReferral && (
            <PreviewGuide>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#6B5B66",
                }}
              >
                <Icons name="IconFileText" fill="transparent" />
                <Typography
                  variant="body4"
                  variantColor="#6B5B66"
                  style={{ marginLeft: 8 }}
                >
                  {t("guideLabel")}
                </Typography>
              </div>
              <UploadPreview file={card.resignationExam.medicalReferral} />
            </PreviewGuide>
          )}

          <AsoContainer
            status={
              card.resignationExam?.validation?.status === "refused"
                ? "declined"
                : "default"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              {card.resignationExam?.validation?.status === "refused" && (
                <StatusCard
                  variant={"declined"}
                  icon={"IconCircle"}
                  iconFilled={true}
                  style={{
                    marginBottom: 12,
                  }}
                />
              )}

              <Typography variant="headline8" variantColor="#53464F">
                {t("subtitle")}
              </Typography>
              <Typography variant="body4" variantColor="#83727D">
                {t("subtitleDescription")}
              </Typography>

              {card.resignationExam?.validation?.status === "refused" &&
                card.resignationExam?.validation?.statusComment && (
                  <RefusedContainer>
                    <Icons name="IconXboxX" fill="transparent" />
                    <StyledPre>
                      {card.resignationExam?.validation?.statusComment}
                    </StyledPre>
                  </RefusedContainer>
                )}
            </div>

            <DatePicker
              label={t("dateLabel")}
              placeholder={t("dateLabel")}
              value={formik.values.date}
              error={formik?.touched?.date && Boolean(formik?.errors?.date)}
              helperText={
                formik?.touched?.date && formik?.errors?.date?.toString()
              }
              onDateChange={async (value) => {
                const date =
                  value && dayjs(value).isValid() ? dayjs(value) : null
                if (!date) return
                const parsedValue = dayjs(value).isValid()
                  ? date?.toISOString()
                  : null

                formik.handleChange({
                  target: { id: "date", value: parsedValue },
                })
              }}
              fullWidth
            />

            <div>
              <Typography variant="body3" variantColor="#53464F">
                {t("uploadLabel")}
              </Typography>
              <Upload
                value={card.resignationExam?.file}
                label={t("uploadLabel")}
                accept={["jpg", "png", "pdf"]}
                folder={`${card.companyId}/${card.employeeId}/${card._id}/resignation/aso/${card.name}`}
                module="employee-pii"
                customFilename={`Exame_demissional_${card.name}`}
                onUpload={async (file) => {
                  formik.handleChange({
                    target: {
                      id: "file",
                      value: file,
                    },
                  })
                }}
                onRemove={async () => {
                  formik.handleChange({
                    target: {
                      id: "file",
                      value: undefined,
                    },
                  })
                }}
              />
            </div>
          </AsoContainer>

          {!isMobile && (
            <ButtonSubmit
              handleSubmit={formik.handleSubmit}
              isLoading={isSubmiting}
            />
          )}
        </RightContainer>
      </Container>

      {isMobile && (
        <ButtonSubmit
          handleSubmit={formik.handleSubmit}
          isLoading={isSubmiting}
        />
      )}
    </MainContainer>
  )
}
