import {
  IconButton,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FileContainer = styled.div<{
  hasFile: boolean
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 32px 40px;
  overflow: overlay;
  border: 1px solid
    ${({ hasFile, theme }) =>
      hasFile ? theme.colors.neutral[80] : theme.colors.feedback.error[50]};
  border-radius: 10px;

  span {
    color: ${({ hasFile, theme }) =>
      hasFile ? theme.colors.neutral[20] : theme.colors.feedback.error[40]};
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ hasFile }) => (hasFile ? 600 : 700)};
    line-height: 22px;
  }
`

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 20px;
`

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
`

export const ThumbnailPreview = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  background-image: url(${({ url }) => (url ? url : "none")});
  background-repeat: no-repeat;
  background-size: cover;
`

export const EmptyFileContainer = styled.div`
  background: ${({ theme }) => theme.colors.feedback.error[90]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-aling: left;
  width: 100%;
`

export const FieldTitle = styled.span`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  font-family: Nunito, sans-serif;
`

export const Value = styled.span<{ hasValue: boolean }>`
  color: ${({ hasValue, theme }) =>
    hasValue ? theme.colors.neutral[30] : theme.colors.feedback.error[40]};
  font-weight: ${({ hasValue }) => (hasValue ? 600 : 700)};
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  font-family: Nunito, sans-serif;
`

export const ShareLinkButton = styled(LinkButton)`
  margin-top: 4px;
  && {
    &.MuiButtonBase-root {
      padding: 0px;
      color: ${({ theme }) => theme.colors.neutral[40]};

      &::before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[40]} !important;
      }
    }
  }
`

export const ShareText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`
