import {
  Button,
  DatePicker,
  Icons,
  Loader,
  Typography,
  dayjs,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2"
import {
  MainContainer,
  AsoContainer,
  ButtonContainer,
  Container,
  LeftContainer,
  PreviewGuide,
  RightContainer,
  RefusedContainer,
  StyledPre,
} from "./styles"
import { Navbar, StatusCard, Upload, UploadPreview } from "@Components"
import { AdmissionalExamStatus, Candidate, HiringCard } from "server/src/types"
import { trpc } from "src/api/trpc"
import { useState } from "react"
import dispatchToast from "@Utils/dispatchToast"
import { useNavigate } from "react-router-dom"

interface ContentProps {
  candidate: Candidate
  card: HiringCard
}

const ButtonSubmit = ({
  handleSubmit,
  isLoading,
}: {
  handleSubmit: () => void
  isLoading: boolean
}) => {
  return (
    <ButtonContainer>
      <Button
        variant="primary"
        size="large"
        onClick={handleSubmit}
        style={{ width: "100%" }}
        disabled={isLoading}
      >
        {isLoading ? (
          <Loader size="small" variant="primary" />
        ) : (
          <>
            Enviar documento
            <Icons name="IconArrowRight" fill="transparent" />
          </>
        )}
      </Button>
    </ButtonContainer>
  )
}

export const Content = ({ card: ogCard, candidate }: ContentProps) => {
  const [card, setCard] = useState<HiringCard>(ogCard)
  const isMobile = useMediaQuery("(max-width: 576px)")

  const navigate = useNavigate()
  const { mutateAsync: updateCard } = trpc.hiring.updateHiringCard.useMutation()
  const { mutateAsync: submitAdmissionalExam, isLoading: isSubmiting } =
    trpc.wizard.submitAdmissionalExam.useMutation()
  const { mutateAsync: removeLimitDate } =
    trpc.hiring.removeLimitDate.useMutation()

  const handleSubmit = () => {
    if (!card.admissionalExam.date) {
      dispatchToast({
        type: "error",
        content: "Por favor preencha a data do exame antes de fazer o envio.",
      })
      return
    }
    if (!card.admissionalExam.file) {
      dispatchToast({
        type: "error",
        content: "Por favor preencha a foto do ASO antes de fazer o envio.",
      })
      return
    }

    submitAdmissionalExam({ flowCardId: card._id })
      .then(async () => {
        navigate("/wizard/finished?type=aso")
      })
      .catch((err) => {
        console.error(err)
        dispatchToast({
          type: "error",
          content: "Algo aconteceu ao enviar o exame admissional",
        })
      })
  }

  return (
    <MainContainer>
      <Navbar />

      <Container>
        <LeftContainer>
          <Typography variant="headline7" variantColor="#F20D7A">
            Exame admissional
          </Typography>
          <Typography variant="body3" variantColor="#83727D">
            Estamos quase finalizando sua admissão e para prosseguir precisamos
            que você envie o resultado do seu exame admissional.
          </Typography>
        </LeftContainer>

        <RightContainer>
          {card.admissionalExam.medicalReferral && (
            <PreviewGuide>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  color: "#6B5B66",
                }}
              >
                <Icons name="IconFileText" fill="transparent" />
                <Typography
                  variant="body4"
                  variantColor="#6B5B66"
                  style={{ marginLeft: 8 }}
                >
                  Guia do exame
                </Typography>
              </div>
              <UploadPreview file={card.admissionalExam.medicalReferral} />
            </PreviewGuide>
          )}

          <AsoContainer
            status={
              card.admissionalExam?.validation?.status === "declined"
                ? "declined"
                : "default"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              {card.admissionalExam?.validation?.status === "declined" && (
                <StatusCard
                  variant={"declined"}
                  icon={"IconCircle"}
                  iconFilled={true}
                  style={{
                    marginBottom: 12,
                  }}
                />
              )}

              <Typography variant="headline8" variantColor="#53464F">
                Atestado de saúde ocupacional
              </Typography>
              <Typography variant="body4" variantColor="#83727D">
                Certifique-se de que o documento anexo está legível e que
                nenhuma informação foi cortada da foto.
              </Typography>

              {card.admissionalExam?.validation?.status === "declined" &&
                card.admissionalExam?.validation?.statusComment && (
                  <RefusedContainer>
                    <Icons name="IconXboxX" fill="transparent" />
                    <StyledPre>
                      {card.admissionalExam?.validation?.statusComment}
                    </StyledPre>
                  </RefusedContainer>
                )}
            </div>

            <DatePicker
              label={"Data do exame"}
              value={
                dayjs(card.admissionalExam?.date).isValid()
                  ? card.admissionalExam?.date
                  : undefined
              }
              onDateChange={async (value) => {
                if (value?.toISOString() === card.admissionalExam.date) return
                let parsedValue: string | null = null
                if (value?.isValid()) {
                  parsedValue = value.toISOString()
                }

                await updateCard({
                  flowCardId: card._id,
                  fields: {
                    admissionalExam: {
                      date: parsedValue,
                    },
                  },
                })
                setCard((cardFromState) => ({
                  ...cardFromState,
                  admissionalExam: {
                    ...cardFromState.admissionalExam,
                    date: parsedValue || undefined,
                  },
                }))
              }}
              fullWidth
            />

            <div>
              <Typography variant="body3" variantColor="#53464F">
                Foto do ASO
              </Typography>
              <Upload
                value={card.admissionalExam?.file}
                label={"Foto do ASO"}
                accept={["jpg", "png", "pdf"]}
                folder={`${card.companyId}/${card.candidateId}/${card._id}/aso`}
                module="employee-pii"
                onUpload={async (file) => {
                  await updateCard({
                    flowCardId: card._id,
                    fields: {
                      admissionalExam: {
                        file,
                      },
                    },
                  })
                  setCard((cardFromState) => ({
                    ...cardFromState,
                    admissionalExam: {
                      ...cardFromState.admissionalExam,
                      file,
                    },
                  }))
                }}
                customFilename={`Exame_${candidate.name.replace(" ", "_")}`}
                onRemove={async () => {
                  await updateCard({
                    flowCardId: card._id,
                    fields: {
                      admissionalExam: {
                        file: null,
                      },
                    },
                  })
                  setCard((cardFromState) => ({
                    ...cardFromState,
                    admissionalExam: {
                      ...cardFromState.admissionalExam,
                      file: undefined,
                    },
                  }))
                }}
              />
            </div>
          </AsoContainer>

          {!isMobile && (
            <ButtonSubmit handleSubmit={handleSubmit} isLoading={isSubmiting} />
          )}
        </RightContainer>
      </Container>

      {isMobile && (
        <ButtonSubmit handleSubmit={handleSubmit} isLoading={isSubmiting} />
      )}
    </MainContainer>
  )
}
