import { useContext, useEffect, useMemo } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { Skeleton, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import {
  HeaderContent,
  LoadingArea,
  StepContainer,
  PageContents,
  MainContainer,
  AvatarCircle,
  CompanyName,
  LogoContainer,
  LogoArea,
  TextArea,
} from "./styles"
import { Context } from "../../context"
import Logo from "../../assets/logo_flash.svg"
import { trpc } from "src/api/trpc"
import { Candidate, Company, Employee, HiringCard } from "server/src/types"
import {
  AcceptedProposal,
  ConfirmProposal,
  DeniedProposal,
  StartProposal,
} from "./screens"

const Proposal = () => {
  const { route } = useParams()
  const navigate = useNavigate()
  const { category, cardId, companyId, candidateId, token } =
    useContext(Context)
  const isMobile = useMediaQuery("(max-width: 768px)")

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const { data, isLoading } = trpc.wizard.getCandidateProposalData.useQuery(
    {
      cardId,
      companyId,
      candidateId,
    },
    {
      enabled: !!category && !!cardId && !!companyId && !!candidateId,
      onSuccess: ({ card }) => {
        const emailLink = card.emailLink.find(
          (emailLink) => emailLink.token && token
        )
        if (emailLink?.expired) {
          navigate("/wizard/invalid")
          return
        }
      },
    }
  )

  useEffect(() => {
    if (route && ["finished", "accepted", "denied"].includes(route)) return

    if (data?.card) {
      const limitDate = data?.card?.limitDates.find((ld) =>
        ld.step.includes("proposal-letter")
      )

      if (!limitDate || (limitDate && new Date() > new Date(limitDate.date))) {
        navigate(`../wizard/invalid`, { replace: true })
        return
      }
    }

    if (data?.candidate.proposalLetterInfo?.proposalStatus === "refused") {
      navigate(`../wizard/proposal/denied`, { replace: true })
      return
    }
    if (data?.candidate.proposalLetterInfo?.proposalStatus === "accepted") {
      navigate(`../wizard/proposal/accepted`, { replace: true })
      return
    }
  }, [route, data, navigate])

  const step = useMemo(() => {
    if (!data) return null

    switch (route) {
      case "start":
        return <StartProposal candidate={data.candidate} />
      case "confirmation":
        return (
          <ConfirmProposal
            candidate={data.candidate}
            card={data.card as HiringCard}
            company={data.company as Company}
          />
        )
      case "accepted":
        return <AcceptedProposal />
      case "denied":
        return <DeniedProposal />
      case "expired":
        return <Navigate replace to={"/wizard/invalid?type=expired"} />
      default:
        return <Navigate replace to={`/wizard/proposal/start`} />
    }
  }, [route, data])

  if (!data || isLoading) {
    return (
      <LoadingArea>
        <Skeleton width={901} height={756} variant="rectangular" />
      </LoadingArea>
    )
  }

  return (
    <MainContainer>
      {route === "confirmation" ? (
        <>
          <HeaderContent backgroundColor={isMobile ? "#F8F6F8" : "#fff"}>
            <LogoContainer>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AvatarCircle
                  hasLogo={false}
                  logoPath={""} // we never had and now we dont even have the prop
                />
                <CompanyName>
                  {data.company?.name || data.company?.legalName || ""}
                </CompanyName>
              </div>
              {!isMobile ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextArea variant={"caption"}>
                    {t("proposal.start.developedBy")}
                  </TextArea>
                  <Logo style={{ marginLeft: "8px" }} />
                </div>
              ) : (
                <></>
              )}
            </LogoContainer>
          </HeaderContent>

          <PageContents>
            <StepContainer>{step}</StepContainer>
            {isMobile ? (
              <LogoArea style={{ width: "100%" }}>
                <TextArea variant={"caption"}>
                  {t("proposal.start.developedBy")}
                </TextArea>
                <Logo style={{ marginLeft: "8px" }} />
              </LogoArea>
            ) : (
              <></>
            )}
          </PageContents>
        </>
      ) : (
        <PageContents
          style={{
            justifyContent: "center",
          }}
        >
          {step}
        </PageContents>
      )}
    </MainContainer>
  )
}

export default Proposal
