import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
`

const Title = styled(Typography)`
  color: var(--color-neutral-dark2);
  margin: 10px 0 5px 0;
  font-weight: 700 !important;
`

const Text = styled(Typography)`
  color: var(--color-neutral-dark4);
  font-weight: 400 !important;
`

export { Container, Title, Text }
