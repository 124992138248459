import { motion, AnimatePresence } from "framer-motion"

export const Accordion = ({
  i,
  expanded,
  isOpen,
  children,
  renderChildren,
}: {
  i?: any
  expanded?: any
  isOpen?: boolean
  children: React.ReactNode
  renderChildren?: React.ReactNode
}) => {
  const isReallyOpen =
    isOpen !== undefined ? isOpen : i === expanded || expanded === "all"

  return (
    <>
      {children}
      <AnimatePresence initial={false}>
        {isReallyOpen && (
          <motion.section
            key="content"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0, pointerEvents: "none" }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            style={{ width: "100%" }}
          >
            {renderChildren}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}
