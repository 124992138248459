import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { cloneDeep } from "lodash-es"

import { Context } from "../../../../context"

import {
  ModalPreviewInstruction,
  RenderField,
  ValidationAccordion,
} from "@Components"

import ArrowRight from "../../../../assets/arrow_right.svg"

import {
  Button,
  Icons,
  LinkButton,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2"

import {
  Container,
  RightContainer,
  LeftContainer,
  StepTitle,
  SubTitle,
  ButtonContainer,
  FieldWrapper,
} from "./styles"
import dispatchToast from "../../../../utils/dispatchToast"
import { hasRequiredFieldWithoutValue } from "@Utils/index"
import { createSegmentTrack, trackList } from "@Utils/segment"
import {
  Candidate,
  ConfirmationDocumentsWithValue,
  HiringCard,
} from "server/src/types"
import { trpc } from "src/api/trpc"

const CandidateStep = ({
  candidate,
  card,
}: {
  candidate: Candidate
  card: HiringCard
}) => {
  const [instructionPreview, setInstructionPreview] = useState<{
    isOpen: boolean
    document?: ConfirmationDocumentsWithValue
  }>({
    isOpen: false,
  })
  const [candidateDocuments, setCandidateDocuments] =
    useState<ConfirmationDocumentsWithValue[]>()

  const [fieldErrors, setFieldErrors] = useState<any>([])
  const [showErrors, setShowErrors] = useState<boolean>(false)

  const { subcategory } = useContext(Context)
  const navigate = useNavigate()

  const { mutateAsync: updateCandidateFields } =
    trpc.candidate.updateCandidateFields.useMutation()
  const { mutateAsync: processingFile } =
    trpc.helper.processingFile.useMutation()
  const { mutateAsync: validateDocument } =
    trpc.candidate.validateCandidateDocument.useMutation()

  const { isLoading } = trpc.wizard.getCandidateDocuments.useQuery(
    {
      candidateId: candidate._id,
      formConfigId: card.formConfigId,
      extensionId: card.formExtensionId,
    },
    {
      onSuccess: (data) => setCandidateDocuments(data),
    }
  )

  const setFieldValue = async ({
    formConfig,
    extensionId,
    fieldType,
    value,
    fieldSectionId,
    fieldId,
    documentId,
  }: {
    formConfig: string
    extensionId?: string
    fieldType: string
    value?: any
    fieldSectionId: string
    fieldId: string
    documentId: string
  }) => {
    let processedFile
    const isFile = fieldType === "file" && value?.path
    if (isFile) processedFile = await processingFile({ path: value?.path })

    setCandidateDocuments((prev) => {
      if (!prev) return
      const candidateDocuments = cloneDeep(prev)

      const indexDoc = candidateDocuments.findIndex((docs) =>
        docs.fields.some(
          (field) =>
            field.sectionId === fieldSectionId && field.fieldId === fieldId
        )
      )
      if (indexDoc === -1) return

      const indexField = candidateDocuments[indexDoc].fields.findIndex(
        (field) => field.fieldId === fieldId
      )
      if (indexField === -1) return
      const parsedValue = isFile ? { ...value, value: processedFile } : value
      candidateDocuments[indexDoc].fields[indexField].value = parsedValue || ""
      if (candidateDocuments[indexDoc].validation?.status !== "waiting") {
        candidateDocuments[indexDoc].validation = {
          sectionId: documentId,
          status: "waiting",
          statusComment: "",
        }
      }
      return candidateDocuments
    })

    await updateCandidateFields({
      candidateId: candidate._id,
      formConfigId: formConfig,
      formConfigExtensionId: extensionId || null,
      values: [
        {
          fieldId,
          type: fieldType,
          sectionId: fieldSectionId,
          value: value || "",
          expirationDate: null,
        },
      ],
    })

    await validateDocument({
      candidateId: candidate._id,
      sectionId: documentId,
      status: "waiting",
      statusComment: undefined,
    })
  }

  useEffect(() => {
    if (candidateDocuments) {
      const filteredDocuments = candidateDocuments?.filter(
        (section) => section.enabled
      )
      setFieldErrors(hasRequiredFieldWithoutValue(filteredDocuments))
    }
  }, [candidateDocuments])

  const handleSubmit = () => {
    const track = trackList?.[subcategory]?.employee?.sendDocContinue
    if (track)
      createSegmentTrack({
        track: track,
      })
    const filteredDocuments = candidateDocuments?.filter(
      (section) => section.enabled
    )
    setFieldErrors(hasRequiredFieldWithoutValue(filteredDocuments))

    const tmp = hasRequiredFieldWithoutValue(filteredDocuments)?.reduce(
      (prev, curr) => {
        return [...prev, ...curr.fields]
      },
      [] as any[]
    )

    if (!filteredDocuments || (tmp && tmp.length > 0)) {
      setShowErrors(true)
      dispatchToast({
        type: "error",
        content: `${tmp?.length} campo${
          tmp && tmp?.length > 1 ? "s" : ""
        } obrigatório${tmp && tmp?.length > 1 ? "s" : ""} não fo${
          tmp && tmp?.length > 1 ? "ram" : "i"
        } preenchido${tmp && tmp?.length > 1 ? "s" : ""}!`,
      })
      return
    }

    navigate(`../wizard/hiring/dependents`, {
      replace: true,
    })
  }

  return (
    <Container>
      <LeftContainer>
        <StepTitle variant={"headline7"}>
          {subcategory !== "pj"
            ? "Documentos da pessoa"
            : "Documentos pessoais e jurídicos"}
        </StepTitle>
        <SubTitle variant={"body3"}>
          Chegamos na etapa de documentação. É hora de enviar as fotos dos seus
          documentos. Lembre-se de enviar imagens com boa qualidade, isso
          aumenta as chances de aprovação. 😉
        </SubTitle>
      </LeftContainer>
      {isLoading || !candidateDocuments ? (
        <RightContainer>
          <Skeleton variant="rectangular" height={"100%"} />
        </RightContainer>
      ) : (
        <RightContainer>
          {candidateDocuments.map((doc, idx) => {
            const isRequired = doc.fields.some(
              (field) => field.fieldRule?.required || false
            )

            const status = doc.validation?.status || "waiting"
            const refusedReason =
              doc.validation?.status === "declined"
                ? doc.validation?.statusComment
                : undefined

            const showErrorMessage = fieldErrors.filter(
              (f) => f._id === doc._id
            )[0]?.fields?.length

            return (
              <ValidationAccordion
                key={idx}
                title={doc.title}
                description={doc.description}
                required={isRequired}
                status={status}
                refusedReason={refusedReason}
                fieldsErrors={
                  showErrors && showErrorMessage > 0
                    ? `${showErrorMessage} campo${
                        showErrorMessage > 1 ? "s" : ""
                      } obrigatório${showErrorMessage > 1 ? "s" : ""} não fo${
                        showErrorMessage > 1 ? "ram" : "i"
                      } preenchido${showErrorMessage > 1 ? "s" : ""}!`
                    : ""
                }
                errorBorder={showErrors && showErrorMessage > 0}
              >
                {doc.fillInstruction?.html && (
                  <LinkButton
                    variant="primary"
                    style={{
                      fontSize: 14,
                      marginBottom: 24,
                    }}
                    onClick={() =>
                      setInstructionPreview({
                        isOpen: true,
                        document: doc,
                      })
                    }
                  >
                    Como encontrar ou preencher esse documento?
                    <Icons
                      fill="transparent"
                      name="IconZoomQuestion"
                      size={14}
                    />
                  </LinkButton>
                )}

                <FieldWrapper>
                  {doc.fields.map((field, index) => (
                    <RenderField
                      key={index}
                      field={field}
                      candidateId={candidate._id}
                      companyId={candidate.companyId}
                      handleChange={async (value) => {
                        if (field.value && value === field.value) return
                        await setFieldValue({
                          formConfig: doc.formConfigId,
                          extensionId: doc.extensionId,
                          fieldType: field.fieldType,
                          value,
                          fieldSectionId: field.sectionId,
                          fieldId: field.fieldId,
                          documentId: doc._id,
                        })
                      }}
                      showError={showErrors}
                    />
                  ))}
                </FieldWrapper>
              </ValidationAccordion>
            )
          })}

          <ButtonContainer
            style={{ paddingTop: "60px", paddingBottom: "20px" }}
          >
            <LinkButton
              variant="primary"
              onClick={() => {
                const track = trackList?.[subcategory]?.employee?.sendDocReturn
                if (track)
                  createSegmentTrack({
                    track: track,
                  })

                navigate(`../wizard/hiring/basic-data`, {
                  replace: true,
                })
              }}
            >
              Voltar
            </LinkButton>

            <div>
              <Button
                variant="primary"
                size="large"
                onClick={handleSubmit}
                style={{
                  width: 311,
                }}
              >
                Continuar
                <ArrowRight style={{ color: "#fff", marginLeft: "7px" }} />
              </Button>
            </div>
          </ButtonContainer>

          {instructionPreview.isOpen && instructionPreview.document && (
            <ModalPreviewInstruction
              isOpen={instructionPreview.isOpen}
              handleClose={() => setInstructionPreview({ isOpen: false })}
              document={instructionPreview.document}
            />
          )}
        </RightContainer>
      )}
    </Container>
  )
}

export default CandidateStep
