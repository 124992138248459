import { useRef, useState, useEffect, useCallback, useMemo } from "react"
import { useParams } from "react-router-dom"
import { StepperSigning } from "@Components"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import {
  Container,
  Header,
  LogoContainer,
  AvatarCircle,
  CompanyName,
  MenuCircle,
  ProgressBarContainer,
  ProgressBar,
  MenuContainer,
} from "./styles"
import { Company } from "server/src/types"

const Mobile = ({ company }: { company: Company }) => {
  const { route } = useParams()

  const ref = useRef<HTMLDivElement>(null)

  const [menuContainerHeight, setMenuContainerHeight] = useState(0)
  const [active, setActive] = useState(false)

  const progress = useMemo(() => {
    switch (route) {
      case "visualizeContract":
        return "33.33%"
      case "confirmData":
        return "66.66%"
      case "signData":
        return "100%"
      default:
        return "0%"
    }
  }, [route])

  useEffect(() => {
    setMenuContainerHeight(ref?.current?.clientHeight || 0)
  }, [])

  const setMenuActive = useCallback(() => {
    setActive(!active)
  }, [active])

  return (
    <Container ref={ref}>
      <ProgressBarContainer>
        <ProgressBar
          initial={{ opacity: 1, width: 0 }}
          animate={{ opacity: 1, width: progress }}
          transition={{ duration: 0.7 }}
        />
      </ProgressBarContainer>
      <Header>
        <LogoContainer>
          <AvatarCircle
            hasLogo={!!company?.logo?.path}
            logoPath={company?.logo?.path || ""}
          />
          <CompanyName>{company?.name || company?.legalName || ""}</CompanyName>
        </LogoContainer>
        <MenuCircle onClick={setMenuActive}>
          <Icons
            name={active ? "IconX" : "IconMenu"}
            fill={"#6D5A66"}
            size={18}
            onClick={() => {}}
          />
        </MenuCircle>
      </Header>
      <MenuContainer menuHeight={menuContainerHeight} active={active}>
        <StepperSigning onItemClick={setMenuActive} />
      </MenuContainer>
    </Container>
  )
}

export default Mobile
