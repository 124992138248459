import { useTranslation } from "react-i18next"
import {
  Container,
  ProposalTitle,
  Description,
  Avatar,
  LogoArea,
} from "./styles"
import Logo from "../../../../assets/logo_flash.svg"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"

const Signed = () => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Avatar>
          <Icons
            name="IconMoodHappy"
            style={{
              width: "64px",
              height: "64px",
              fill: "transparent",
              color: "#fff",
            }}
          />
        </Avatar>
        <div style={{ maxWidth: "400px" }}>
          <ProposalTitle variant={"headline7"}>
            Agradecemos pelo envio da sua assinatura
          </ProposalTitle>
          <Description variant={"body3"}>
            Assim que as pessoas pendentes assinarem, você receberá uma cópia do
            documento.
          </Description>
        </div>
      </Container>
      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </div>
  )
}

export default Signed
