import { useContext } from "react"
import { Button } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "react-i18next"

import {
  Container,
  ProposalTitle,
  ExpireDate,
  Description,
  Avatar,
  LogoArea,
} from "./styles"

import Logo from "../../../../assets/logo_flash.svg"
import { Context } from "src/context"
import {
  Contract,
  Candidate,
  HiringCard,
  ResignationCard,
  SignType,
} from "server/src/types"
import { createSegmentTrack, trackList } from "@Utils/segment"

export const InstitutionContent = ({
  card,
  contract,
}: {
  card: HiringCard | ResignationCard
  contract: Contract
}) => {
  const { subcategory } = useContext(Context)

  const navigate = useNavigate()
  const { expireDate } = useParams()

  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  })

  const signature = contract?.signature || []

  const foundCandidate = signature.find(
    (s) => s.type === SignType.candidate || s.type === SignType.employee
  )

  return (
    <>
      <Container>
        <Avatar>{foundCandidate?.name?.substring(0, 1)}</Avatar>
        <ProposalTitle variant={"headline6"}>
          <span>
            Hora de assinar o termo de compromisso de estágio do candidato(a){" "}
            <span style={{ textTransform: "capitalize" }}>
              {foundCandidate?.name?.split(" ")?.[0]}
            </span>
          </span>
        </ProposalTitle>
        {expireDate ? (
          <ExpireDate>Responda até 25/08/2022 às 22h</ExpireDate>
        ) : null}
        <Description variant={"body3"}>
          Por favor assine o documento o mais rápido possível.
        </Description>
        <Button
          onClick={() => {
            const track = trackList?.[subcategory]?.employee?.analyzeDocument
            if (track)
              createSegmentTrack({
                track: track,
              })

            navigate(`../wizard/signing/visualizeContract`, {
              replace: true,
            })
          }}
          variant="primary"
          size="large"
          style={{ alignSelf: "center" }}
        >
          {t("signing.start.buttonLabel")}
        </Button>
      </Container>

      <LogoArea>
        {t("signing.start.developedBy")}
        <Logo style={{ marginLeft: "8px" }} />
      </LogoArea>
    </>
  )
}
