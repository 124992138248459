import { useMemo } from "react"
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"

import Desktop from "./Desktop"
import Mobile from "./Mobile"

const DeniedProposal = () => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const ComponentRender = useMemo(
    () => (isMobile ? Mobile : Desktop),
    [isMobile]
  )

  return <ComponentRender />
}

export default DeniedProposal
