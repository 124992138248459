import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const StyledPdfHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f6f8;
  border: 1px solid #ebe6e9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
`

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.primary};
      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 16px 40px ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

export const StyledIcons = styled(Icons)`
  && {
    &:hover {
      opacity: 0.5;
    }
  }
`

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #fff;
  padding: 82px 112px;
  margin: 48px 32px;
`

export const LeftContainer = styled.div`
  width: 515px;
  padding-right: 92px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-right: 40px;
  }
`

export const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

export const RightSubContainer = styled.div`
  display: flex;
  padding: 40px 44px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`
