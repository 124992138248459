import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const PreviewContainer = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr max-content;
  align-items: center;
  gap: 10px;
  padding: 32px 40px;
  border: 1px solid ${({ theme }) => theme.colors.secondary[70]};
  border-radius: 12px;
  overflow: scroll;
`

export const PreviewImage = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

export const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      padding: 0px;
      color: ${({ theme }) => theme.colors.neutral[40]};

      &::before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[40]} !important;
      }
    }
  }
`
