import styled from "styled-components"

import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral[100]};

  padding: 16px 24px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`

export const StyledLinkButton = styled(LinkButton)`
  margin: auto 0;
`

export const BackButton = styled(Button)`
  && {
    width: 200px;
    margin-left: 20px;
  }
`
export const ContinueButton = styled(Button)`
  && {
    width: 200px;
  }
`
