import Mobile from "./Mobile"
import Desktop from "./Desktop"

import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import {
  HiringCard,
  Company,
  HiringFlowList,
  Candidate,
} from "server/src/types"

export const Header = ({
  candidate,
  company,
  card,
  flowList,
}: {
  candidate: Candidate
  company: Company
  card: HiringCard
  flowList: HiringFlowList
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)")

  const HeaderRender = isMobile ? (
    <Mobile
      candidate={candidate}
      company={company}
      card={card}
      flowList={flowList}
    />
  ) : (
    <Desktop
      candidate={candidate}
      company={company}
      card={card}
      flowList={flowList}
    />
  )

  return HeaderRender
}
