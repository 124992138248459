import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2"
import { useSearchParams } from "react-router-dom"
import { MainContainer, PageContents } from "./styles"

import Desktop from "./Desktop"
import Mobile from "./Mobile"

const InvalidPage = () => {
  const [searchParams] = useSearchParams()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const type =
    (searchParams.get("type") as "invalid" | "expired" | "corrupted") ||
    "invalid"

  return (
    <MainContainer>
      <PageContents>
        {isMobile ? <Desktop type={type} /> : <Mobile type={type} />}
      </PageContents>
    </MainContainer>
  )
}

export default InvalidPage
