import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import {
  Finished,
  Invalid,
  MainPage,
  Proposal,
  Signing,
  AdmissionExam,
  SurveyAnswer,
  ResignationExam,
} from "../pages"
import AuthGuard from "./AuthGuard"

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route path="/wizard/hiring/:route" element={<MainPage />} />
          <Route path="/wizard/proposal/:route" element={<Proposal />} />
          <Route path="/wizard/finished" element={<Finished />} />
          <Route path="/wizard/signing/:route" element={<Signing />} />
          <Route path="/wizard/aso" element={<AdmissionExam />} />
          <Route path="/wizard/resignationAso" element={<ResignationExam />} />

          <Route
            path={"/wizard/engagement/survey/answer/:step"}
            element={<SurveyAnswer />}
          />
        </Route>

        <Route path="/wizard/invalid" element={<Invalid />} />
        <Route
          path="*"
          element={<Navigate to="/wizard/invalid?type=invalid" replace />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
