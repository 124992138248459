import { useEffect, useState } from "react"
import {
  Button,
  Icons,
  LinkButton,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2"
import { CompanyTrack, EmployeeTrack } from "src/types"
import { createSegmentTrack } from "@Utils/segment"
import dispatchToast from "@Utils/dispatchToast"
import { StyledSignContainer } from "./styles"

interface Props {
  dismiss: () => void
  update: any
  confirm: any
  lastTexting: any
  loading: boolean
  segmentTrack: CompanyTrack | EmployeeTrack
  modalInfo?: any
}

export const Texting = ({
  dismiss,
  lastTexting,
  update,
  confirm,
  loading,
  segmentTrack,
  modalInfo,
}: Props) => {
  const [content, setContent] = useState("")

  useEffect(() => {
    if (lastTexting && Object.keys(lastTexting).length) {
      const { text } = lastTexting
      setContent(text)
    }
  }, [lastTexting])

  useEffect(() => {
    if (modalInfo && Object.keys(modalInfo).length) {
      const { text } = modalInfo
      setContent(text)
    }
  }, [modalInfo])

  const handleFinish = () => {
    if (segmentTrack)
      createSegmentTrack({ track: segmentTrack.signContractSign })

    if (!content) {
      dispatchToast({
        variant: "toast",
        type: "error",
        title: "Por favor, digite sua assinatura",
      })
      return
    }
    if (modalInfo?.id) {
      update({ text: content, id: modalInfo?.id })
    } else {
      confirm({ text: content })
    }
  }

  const closeModal = () => {
    if (segmentTrack)
      createSegmentTrack({ track: segmentTrack.signContractCancel })
    dismiss()
  }

  return (
    <>
      <StyledSignContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <LinkButton
            variant="primary"
            style={{ alignSelf: "center" }}
            onClick={() => {
              if (segmentTrack)
                createSegmentTrack({ track: segmentTrack.signContractClean })
              setContent("")
            }}
          >
            Limpar
          </LinkButton>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
            marginBottom: "40px",
            height: "154px",
          }}
        >
          <input
            id="textSignature"
            type={"text"}
            value={content}
            style={{
              width: "100%",
              marginRight: "16px",
              marginLeft: "16px",
              background: "#F8F6F8",
              outline: "none",
              borderBottom: "1px solid #D1C7CE",
              fontFamily: "LiuJianMaoCao",
              fontWeight: 400,
              fontSize: "72px",
            }}
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
      </StyledSignContainer>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
      >
        <LinkButton
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={closeModal}
        >
          Voltar
        </LinkButton>

        <Button
          size="large"
          variant="primary"
          style={{ alignSelf: "center" }}
          onClick={handleFinish}
          disabled={!!loading}
        >
          {loading ? (
            <Loader size="small" variant="secondary" />
          ) : (
            <>
              Assinar
              <Icons
                name="IconArrowRight"
                style={{
                  fill: "transparent",
                  color: "#fff",
                  marginLeft: "8px",
                }}
              />
            </>
          )}
        </Button>
      </div>

      <span
        id="textToUpload"
        style={{
          fontFamily: "LiuJianMaoCao",
          fontWeight: 400,
          fontSize: "72px",
          display: "none",
          paddingBottom: "10px",
        }}
      >
        {content}
      </span>
    </>
  )
}
