import { IconButton, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { MouseEvent, ReactNode } from "react"
import {
  AccordionBody,
  AccordionContainer,
  AccordionHeader,
  RefusedContainer,
  StyledPre,
} from "./styles"
import { StatusCard } from "../"
import { Skeleton } from "@mui/material"

interface ValidationAccordionProps {
  title: string
  description?: string
  status?: "waiting" | "declined" | "approved" | "optional"
  refusedReason?: string
  fieldsErrors?: string
  errorBorder?: boolean
  children: ReactNode
  disabled?: boolean
  required?: boolean
  handleEdit?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
  handleDelete?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>
}

export const ValidationAccordion = ({
  title,
  description,
  status,
  refusedReason,
  fieldsErrors,
  errorBorder,
  children,
  disabled = false,
  required = true,
  handleEdit,
  handleDelete,
}: ValidationAccordionProps) => {
  // const statusMock = {
  //   ["declined"]: {
  //     text: "Reprovado",
  //     backgroundColor: "#FFECD6",
  //     textColor: "#4C2900",
  //     iconColor: "#FEA034",
  //   },
  //   ["approved"]: {
  //     text: "Aprovado",
  //     backgroundColor: "#D7F9F3",
  //     textColor: "#094338",
  //     iconColor: "#2ED9B8",
  //   },
  //   ["optional"]: {
  //     text: "Opcional",
  //     backgroundColor: "#FFE0EF",
  //     textColor: "#F20D7A",
  //     iconColor: "#F20D7A",
  //   },
  // }

  const isLoading = false // mutation should provide this prop
  if (isLoading)
    return (
      <Skeleton
        height={150}
        width={"100%"}
        style={{
          transform: "none",
        }}
      />
    )

  return (
    <AccordionContainer isRefused={errorBorder || status === "declined"}>
      <AccordionHeader
        expandIcon={
          <IconButton
            size="medium"
            variant="filled"
            icon={"IconChevronDown"}
            disabled={disabled}
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {status && status !== "waiting" && (
            <StatusCard
              variant={status}
              icon={status !== "optional" ? "IconCircle" : undefined}
              iconFilled={true}
              style={{
                marginBottom: 16,
              }}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <Typography
              variant="headline8"
              style={{
                color: "#53464F",
              }}
            >
              {title}

              {required && (
                <span
                  style={{
                    color: "#FEA034",
                  }}
                >
                  *
                </span>
              )}
            </Typography>
          </div>

          {description && (
            <Typography
              variant="body4"
              style={{
                color: "#83727D",
                marginTop: 4,
              }}
            >
              {description}
            </Typography>
          )}

          {fieldsErrors && (
            <Typography
              variant="body4"
              style={{
                color: "#C96C01",
                fontWeight: "700",
                marginTop: 4,
              }}
            >
              {fieldsErrors}
            </Typography>
          )}
        </div>

        {(handleDelete || handleEdit) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 16px",
            }}
          >
            {handleEdit && (
              <div>
                <IconButton
                  size="medium"
                  variant="line"
                  icon={"IconPencil"}
                  onClick={handleEdit}
                  disabled={disabled}
                />
              </div>
            )}

            {handleDelete && (
              <div>
                <IconButton
                  size="medium"
                  variant="line"
                  icon={"IconTrash"}
                  onClick={handleDelete}
                  disabled={disabled}
                />
              </div>
            )}
          </div>
        )}
      </AccordionHeader>

      <AccordionBody>
        {status === "declined" && refusedReason && (
          <RefusedContainer>
            <Icons name="IconXboxX" fill="transparent" />
            <StyledPre>{refusedReason}</StyledPre>
          </RefusedContainer>
        )}

        {children}
      </AccordionBody>
    </AccordionContainer>
  )
}
