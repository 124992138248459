import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 82px 112px 48px 112px;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 80px;
    flex-direction: column;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 60px 40px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    padding: 30px 15px;
  }
`

const LeftContainer = styled.div`
  margin: 54px 92px 0 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-right: 40px;
  }
`

const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

const StepTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.primary};
  max-width: 213px;
  margin: 0;
  padding: 0;
  margin-bottom: 22px;
`

const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #856f7e;
  max-width: 348px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

const ToggleContainer = styled.div`
  border: 1px solid #e8e3e6;
  border-radius: 6px;
  padding: 12px 15px;
  margin-top: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const ButtonContained = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;

  border: 1px solid ${({ theme }) => theme?.colors?.primary};
  border-radius: 12px;
  margin-top: 40px;

  p {
    color: ${({ theme }) => theme?.colors?.primary};
  }

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const SectionTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #856f7e;
`

const SectionSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #856f7e;
  margin-bottom: 24px;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const AccordionChildren = styled.div`
  border: 1px solid #e8e3e6;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 44px 40px 44px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const AccordionElement = styled.div<{ hasId: boolean }>`
  border-bottom: 1px solid #ebe5e9;
  opacity: ${({ hasId }) => (hasId ? 1 : 0.5)};
  pointer-events: ${({ hasId }) => (hasId ? "auto" : "none")};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column-reverse;
  }
`

const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

const PreviewDocContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
  cursor: pointer;
`

const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

const IconContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #f6f3f6;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
  }
`

const OptionalBadge = styled.div`
  max-width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 4px 12px;
  background: ${({ theme }) => theme?.colors?.primary};
  border-radius: 24px;

  margin-left: 0px;
  margin-bottom: 4px;
  margin-top: 44px;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }
`

export const DependentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
`

export {
  Container,
  LeftContainer,
  RightContainer,
  StepTitle,
  SubTitle,
  ToggleContainer,
  ButtonContained,
  SectionTitle,
  SectionSubTitle,
  FlexColumn,
  ChildrenContainer,
  AccordionChildren,
  AccordionElement,
  ButtonContainer,
  IconContainer,
  PreviewTextContainer,
  PreviewDocContainer,
  PreviewImageContainer,
  OptionalBadge,
}
