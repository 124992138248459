import styled from "styled-components"
import { motion } from "framer-motion"

const Container = styled.div`
  padding: 15px 76px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  @media screen and (max-width: 968px) {
    padding: 15px 20px;
  }
`

const CompanyName = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #3c2d37;
  margin: 0 12px;
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`

const HeaderContent = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const AvatarCircle = styled.div<{ logoPath?: string; hasLogo?: boolean }>`
  width: 51px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme, hasLogo }) =>
    !!!hasLogo ? theme.colors.primary : "transparent"};
  background-image: url(${({ logoPath }) => logoPath});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const CenterContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

const RightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #a4a6ab;

  @media screen and (max-width: 480px) {
    display: none;
  }
`

const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: rgba(74, 78, 87, 0.1);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
`

const ProgressBar = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100%;
`

export {
  Container,
  HeaderContent,
  CompanyName,
  LogoContainer,
  AvatarCircle,
  CenterContainer,
  RightContainer,
  ProgressBarContainer,
  ProgressBar,
}
