interface dispatchToastParams {
  content?: string
  title?: string
  description?: string
  variant?: string
  type: string
}

export default function dispatchToast(detail: dispatchToastParams) {
  dispatchEvent(new CustomEvent("showToast", { detail }))
}
