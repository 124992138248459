import {
  Button,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  background-color: #fff;
  padding: 82px 112px;
  margin: 48px 32px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    padding: 40px 24px;
    margin: 0;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      border: transparent;
    }
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin: 0;
    overflow-y: auto;

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      border: transparent;
    }
  }
`

const LeftContainer = styled.div`
  max-width: 515px;
  width: 100%;
  padding-right: 92px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 32px;
    padding: 0;
  }
`

const RightContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    min-width: 450px;
  }
`

const StepTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  max-width: 348px;

  margin-bottom: 22px;
`

const ActionFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    flex-direction: column-reverse;
    gap: 24px;

    > :nth-child(2) {
      width: 100%;
    }
  }
`

const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  max-width: 348px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    margin-bottom: 40px;
  }
`

const AccordionChildren = styled.div`
  border: 1px solid #e8e3e6;
  border-top: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 44px 40px 44px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.lg}px`}) {
    text-align: left;
  }
`

const AccordionChildrenItems = styled.div`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
  }
`

const ConfirmButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  background-color: ${({ theme }) => theme?.colors?.primary};

  border-radius: 4px 16px 4px 4px;

  cursor: pointer;

  &:disabled {
    background-color: #ccc;
    pointer-events: none;
  }

  p {
    color: #fff;
    font-weight: 700;
  }

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    margin-top: 10px;
  }
`

const PreviewImageContainer = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: cover;
`

const PreviewDocContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  padding: 12px 24px 12px 12px;

  border: 1px solid #e8e3e6;
  border-radius: 4px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    width: 100%;
  }
  cursor: pointer;
`

const PreviewTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`

const IconContainer = styled.div`
  display: inline-flex;
  border-radius: 50%;
  padding: 12px;
  background-color: #f6f3f6;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    flex-direction: column;
    margin-top: 10px;
  }
`

const RightSubContainer = styled.div`
  display: flex;
  padding: 40px 44px;
  border: 1px solid #ebe6e9;
  border-radius: 8px;
`

const StyledLinkButton = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      color: ${({ theme }) => theme.colors.primary};
      border-bottom-color: ${({ theme }) => theme.colors.primary};
      align-self: center;

      &:before {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
      }

      &:active {
        color: ${({ theme }) => theme.colors.primary};

        &:before {
          border-bottom-color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`

const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.primary};
      align-self: center;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 16px 40px ${({ theme }) => theme.colors.primary};
      }
    }
  }
`

export {
  Container,
  LeftContainer,
  RightContainer,
  StepTitle,
  SubTitle,
  AccordionChildren,
  AccordionChildrenItems,
  ButtonContainer,
  ConfirmButton,
  PreviewImageContainer,
  PreviewDocContainer,
  PreviewTextContainer,
  IconContainer,
  RightSubContainer,
  StyledButton,
  StyledLinkButton,
  ActionFooterContainer,
}
