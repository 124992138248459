import { CSSProperties } from "react"

import { IconTypes, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { StatusContainer } from "./styles"

interface StatusCard {
  icon?: IconTypes
  iconPosition?: "right" | "left"
  iconFilled?: boolean
  variant?: "declined" | "approved" | "optional"
  style?: CSSProperties
}

export const StatusCard = ({
  icon,
  iconPosition = "left",
  iconFilled = false,
  variant,
  style,
}: StatusCard) => {
  const variants = {
    ["declined"]: {
      text: "Reprovado",
      backgroundColor: "#FFECD6",
      borderColor: "#FFECD6",
      textColor: "#4C2900",
      iconColor: "#FEA034",
    },
    ["approved"]: {
      text: "Aprovado",
      backgroundColor: "#D7F9F3",
      borderColor: "#D7F9F3",
      textColor: "#094338",
      iconColor: "#2ED9B8",
    },
    ["optional"]: {
      text: "Opcional",
      backgroundColor: "#FFE0EF",
      borderColor: "#FFE0EF",
      textColor: "#F20D7A",
      iconColor: "#F20D7A",
    },
  }

  const { backgroundColor, iconColor, textColor, borderColor, text } =
    variants[variant || "optional"]
  return (
    <StatusContainer
      style={{
        backgroundColor: backgroundColor,
        borderColor: borderColor || backgroundColor,
        ...style,
      }}
    >
      {icon && iconPosition === "left" && (
        <Icons
          name={icon}
          color={iconColor || borderColor}
          fill={iconFilled ? iconColor : "transparent"}
          width={6}
          height={6}
          style={{ marginRight: 5 }}
        />
      )}

      <Typography
        variant="caption"
        style={{
          fontWeight: 600,
          color: textColor,
        }}
      >
        {text}
      </Typography>

      {icon && iconPosition === "right" && (
        <Icons
          name={icon}
          fill={iconFilled ? iconColor : "transparent"}
          color={iconColor || borderColor}
          width={6}
          height={6}
          style={{ marginLeft: 5 }}
        />
      )}
    </StatusContainer>
  )
}
